@use "../../theme/utils/variables" as *;

.tecma-dropDown {
  position: relative;
  ul {
    margin: 0;
    padding: 0.25rem 0;
    background-color: white;
    box-shadow: 0rem 0rem 0.5rem 0.025rem rgba(0, 0, 0, 0.31);
    position: relative;
    li {
      list-style: none;
    }
    .tecma-dropDown-element {
      &:hover {
        background-color: $neutral-95;
      }
      .tecma-button {
        width: 100%;
        text-align: left;
      }
    }
  }
  &.arrow.bottom-center {
    margin-top: 0.4rem;
    &::before {
      content: "";
      position: absolute;
      top: -0.4rem;
      left: 50%;
      transform: translate(-50%, 0);
      border-bottom: 0.5rem solid white;
      border-right: 0.5rem solid transparent;
      border-left: 0.5rem solid transparent;
      border-top: 0 solid transparent;
      z-index: 9;
    }
  }
  .tecma-dropDown-divider {
    width: 100%;
    height: 0.0625rem;
    background-color: $neutral-90;
    margin: 0.15rem 0;
  }
  .tecma-dropDown-link {
    text-decoration: none;
    color: inherit;
  }
}
