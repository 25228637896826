.edit-custom-css {
  position: relative;
}

.css-editor {
  border: 1px solid #989696;
  border-radius: 4px;
  padding: 20px;
  background-color: #fff;

  .CodeMirror {
    pre.CodeMirror-line {
      font-family: 'Segoe UI';
    }
  }
}
