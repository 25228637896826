.modal-label {
  background-color: $primary-color;
  color: $text-color;
  min-width: 150px;
  height: 60px;
  font-size: 0.85rem;
  line-height: 1.3;
  text-align: center;
  position: absolute;
  left: 3%;
  top: 42px;
  z-index: 9;
  padding: 0.5rem;
  border-radius: 5px 5px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  transition: transform 0.3s;
  @media screen and (max-width: $mobile) {
    top: 30%;
  }
  &:hover {
    transform: scale(1.05);
    transform-origin: bottom center;
  }

  span {
    font-size: 1.1rem;
    font-weight: $bold;
  }
}

.closeModal {
  color: $white;
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 999;
  cursor: pointer;
}

.scrollable-content {
  overflow-y: auto;
  max-height: 300px;
}


.building-map {
  display: flex;
  margin-top: $md-space;
  overflow: hidden;
  @media screen and (max-width: $small-desktop) {
    justify-content: center;
  }
  .building-map__section {
    &.views-wrapper {
      flex: 0 0 20%;
      max-width: 200px;
      margin-top: -0.5rem;
      .views-wrapper__plan {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.5rem;
        .plan-header span {
          margin: 0 0 0 2rem;
        }
      }
    }
  }
  .main-wrapper {
    flex: 0 0 80%;
    margin: 0 $lg-space;
    @media screen and (max-width: $small-desktop) {
      flex: 0 0 95%;
      margin: 0;
    }
  }
}

.views-wrapper__header {
  position: relative;
  .views-wrapper__logo {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .views-wrapper__title {
    font-size: 0.75rem;
    line-height: 1.3;
    text-transform: uppercase;
    border-bottom: 1px solid $primary-color;
    text-align: right;
    padding: 3rem 1rem 0 0;
    font-weight: $bold;
    span {
      color: $primary-color;
      font-size: 1.4rem;
    }
  }
}

.floor-section.building-container {
  flex-direction: row!important;
  justify-content: center!important;
  align-items: flex-end;
  width: 80%;
  .single-building {
    display: inline-flex;
    flex-direction: column;
    flex-grow: 0.5;
    margin-left: 50px;
    margin-right: 50px;
    height: 80%;
    max-width: 50%;

    .building-title {
      margin-bottom: 50px;
      text-align: center;
      font-size: 20px;
    }

    .blocks-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}

.view-icon {
  display: inline-block;
  cursor: pointer;
}
.piano-text {
  min-width: 35px;
  display: inline-block;
  text-align: center;
}

.building-container:not(:first-child) {
  margin-top: 12rem;
}

.building-map:not(.building-page) .building-container .building-block.fixed-place {
  background-color: #333!important;
  color: #fff!important;

  &:before, &:after {
    content: unset!important;
  }
}

.building-map:not(.building-page) .building-container .building-block {
  &:after {
    background-size: calc(100% - 10px);
  }
}

.building-container {
  width: 1000px;
  margin: 7rem auto 5rem auto;
  position: relative;

  .building-plan {
    display: flex;
    justify-content: center;
    position: relative;
    &:after {
      content: "";
      font-size: 1.2rem;
      text-align: center;
      width: 25px;
      position: absolute;
      right: -2rem;
      top: 50%;
      transform: translateY(-50%);
    }
    &.building-plan_PT:after, &.building-plan_0:after {
      content: "PT";
    }
    &.building-plan_1:after {
      content: "1";
    }
    &.building-plan_2:after {
      content: "2";
    }
    &.building-plan_3:after {
      content: "3";
    }
    &.building-plan_4:after {
      content: "4";
    }
    &.building-plan_5:after {
      content: "5";
    }
    &.building-plan_6:after {
      content: "6";
    }
    &.building-plan_7:after {
      content: "7";
    }
    &.building-plan_8:after {
      content: "8";
    }
    &.building-plan_9:after {
      content: "9";
    }
    &.building-plan_10:after {
      content: "10";
    }
    &.building-plan_11:after {
      content: "11";
    }
    &.building-plan_12:after {
      content: "12";
    }
    &.building-plan_13:after {
      content: "13";
    }
    &.building-plan_14:after {
      content: "14";
    }
    &.building-plan_15:after {
      content: "15";
    }
    &.building-plan_16:after {
      content: "16";
    }
    &.building-plan_17:after {
      content: "17";
    }
    &.building-plan_18:after {
      content: "18";
    }
    &.building-plan_19:after {
      content: "19";
    }
    &.building-plan_20:after {
      content: "20";
    }

    .building-block {
      height: 30px;
      background: $floor-color-na;
      color: #fff;
      text-align: center;
      font-size: 1rem;
      line-height: 2.8rem;
      box-shadow: inset 5px 5px 0px $background-color;
      position: relative;
      &:before{
        content: '';
        @include icon(15px , no-repeat, center bottom, 100%, 100%);
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      &.fixed-place {
        background-color: #333!important;
        color: #fff!important;
        z-index: 2;

        &:before, &:after {
          content: unset!important;
        }
      }
      &:after{
        content: '';
        width: calc(100% - 5px);
        height: 40%;
        background-color: rgba($black, 0.1);
        display: block;
        position: absolute;
        bottom: 0;
        left: 5px;
        z-index: 0!important;
      }
      &.available {
        background: $floor-color;
        cursor: pointer;
      }
      &.active {
        background-color: $floor-color-sc;

      }
      &[id*="Z"] {
        background: #CCCCCC;
        //background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 4%, rgba(255,249,158,1) 100%);
        opacity: 1;
        &:after{
          content: none;
        }
      }
    }
  }
}

/* ----- BUILDING EST ---- */

.building-container__est .building-block {
  flex-basis: calc(100% / 7);
}
/* piano terra */
.building-block#Z02 {
  flex-basis: calc((100% / 7) * 3.7);
}
.building-block#E02 {
  flex-basis: calc((100% / 7) * 1.5);
}
.building-block#E01 {
  flex-basis: calc((100% / 7) * 0.8);
}
/* piano 1 */
.building-block#C15 {
  flex-basis: calc((100% / 7) / 3 * 2);
}
.building-block#C16 {
  flex-basis: calc((100% / 7) / 3);
}
/* piano 6 */
.building-block#A61 {
  flex-basis: calc((100% / 7) * 0.8);
}
.building-block#C62 {
  flex-basis: calc((100% / 7) * 2.8);
}
/* piano 7 */
.building-block#A71 {
  flex-basis: calc((100% / 7) * 0.7);
}
.building-block#A72 {
  flex-basis: calc((100% / 7) * 2);
}
.building-block#C71 {
  flex-basis: calc((100% / 7) * 2.7);
}
/* piano 8 */
.building-block#A81 {
  flex-basis: calc((100% / 7) * 1.3);
}
.building-block#A82 {
  flex-basis: calc((100% / 7) * 2);
}
.building-block#C81 {
  flex-basis: calc((100% / 7) * 2.3);
}

// graphic customization

/* piano 3 */
.building-block#C31 {
  &::before{
    background-size: 12px;
  }
}
/* piano 4 */
.building-block#A44 {
  &::before{
    background-size: 40px;
  }
}
.building-block#A43 {
  &::before{
    background-size: 12px;
  }
}
.building-block#C42 {
  &::before{
    background-size: 40px;
  }
}
/* piano 6 */
.building-block#A61 {
  &::before{
    background-size: 40px;
  }
}
/* piano 7 */
.building-block#A72 {
  &::before{
    background-size: 12px;
  }
}
.building-block#C71 {
  &::before{
    background-size: 150px;
  }
}
/* piano 8 */
.building-block#A81 {
  &::before{
    background-size: 150px;
  }
}





/* ----- BUILDING SUD ---- */

.building-container__sud .building-block {
  flex-basis: calc(100% / 5);
}
/* piano terra */
.building-block#Z04 {
  flex-basis: 100%;
}
/* piano 6 */
.building-block#B62 {
  flex-basis: calc((100% / 5) * 0.8);
}
.building-block#A61_sud {
  flex-basis: calc((100% / 5) * 1.8);
}
/* piano 7 */
.building-block#A72 {
  flex-basis: calc((100% / 5) * 2.5);
}
.building-container__sud .building-block#A71 {
  flex-basis: calc((100% / 5) * 1.5);
}
/* piano 8 */
.building-block#A82_sud {
  flex-basis: calc((100% / 5) * 2);
}

// graphic customization

/* piano 8 */
.building-block#A82_sud {
  &::before{
    background-size: 150px;
  }
}
/* piano 6 */
.building-block#A61_sud {
  &::before{
    background-size: 150px;
  }
}
.building-block#B61 {
  &::before{
    background-size: 40px;
  }
}
.building-block#B62 {
  &::before{
    background-size: 12px;
  }
}
/* piano 4 */
.building-block#A42 {
  &::before{
    background-size: 12px;
  }
}



/* ----- BUILDING NORD ---- */

.building-container__nord .building-plan {
  justify-content: left;
}
.building-container__nord .building-block {
  flex-basis: calc(100% / 4);
}
/* piano terra */
.building-block#Z05 {
  flex-basis: 100%;
}
/* piano 6 */
.building-block#D61 {
  flex-basis: calc((100% / 4) * 0.8);
}
.building-block#D62 {
  box-shadow: inset 5px 0px 0px $background-color;
  &.available{
    &:before{
      background-color: $floor-color;
    }
  }
    &.active{
      &:before{
        background-color: $floor-color-sc;
      }
  }
}
.building-block#D62::before {
  content: "";
  width: 150%;
  background: $floor-color-na;
  display: block;
  height: 30px;
  position: absolute;
  left: 0;
  bottom: 30px;
  box-shadow: inset 5px 5px 0px $background-color;
}
/* piano 7 */
.building-block#C73 {
  flex-basis: calc((100% / 4) * 2);
}
/* piano 8 */
.building-block#C83 {
  flex-basis: calc((100% / 4) * 2);
}


// graphic customization

/* piano 8 */
.building-block#C83 {
  &::before{
    background-size: 150px;
  }
}
/* piano 7 */
.building-block#C73 {
  &::before{
    background-size: 12px;
  }
}
/* piano 6 */
.building-block#C61 {
  &::before{
    background-size: 40px;
  }
}
.building-block#D61 {
  &::before{
    background-size: 40px;
  }
}



// duplex
.building-block.available span.duplex::before,
.building-block.available span.duplex::after {
  background-color: $floor-color !important;
}
.building-block.active span.duplex::before,
.building-block.active span.duplex::after {
  background-color: $secondary-color !important;
}


// Apartments code
.building-container .building-block span.appartment-code {
  font-size: 1rem;
  float: right;
  position: relative;
  right: 0.5rem;
  bottom: 0.17rem;
  opacity: 0.7;
}
.building-container .building-block:not(.available) span.appartment-code {
  display: none;
}
.building-container .building-block#A81 span.appartment-code {
  float: none;
}

.building-map {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-arrow {
  display: flex;
  width: 70%;
  justify-content: center;
  
  z-index: 10;

  .arrow-down {
    width: 0; 
    height: 0; 
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    cursor: pointer;
    border-top: 25px solid $secondary-color;
  }

  .arrow-up {
    width: 0; 
    height: 0; 
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    cursor: pointer;
    border-top: 25px solid $secondary-color;
    transform: rotate(180deg);
    position: absolute;
    top: 50px;
  }
}

.modal.VISTE {
  z-index: 50;
}

// LEGENDA
.legenda .legendaContainer {
  background-color: rgba(252,215,184,.7);
  padding: 1rem 1rem 1rem;
  display: flex;
  flex-wrap: wrap;
  width: 220px;
  border-radius: 20px 0 0 20px;

  .label {
    color: #404a2f;
    font-size: .7rem;
    text-align: right;
    width: 100%;
    margin: 0 0 .8rem;
  }

  p {
    background-color: #fff;
    font-size: .7rem;
    line-height: 1.2;
    letter-spacing: -.04em;
    padding: .5rem 2rem .5rem .5rem;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    margin: 0px!important;
    margin-bottom: 32px!important;
    position: relative;
    right: -30px;
    border-radius: 20px;

    &:before {
      content: unset!important;
    }

    &:after {
      content: "";
      width: 25px;
      height: 25px;
      display: inline-block;
      margin: 0 .2rem;
      position: absolute;
      right: -20px;
      top: -12px;
      border: 15px solid #fff;
      border-image-source: initial;
      border-radius: 50%;
    }

    &.available:after {
      background: #d1d569;
    }

    &.not-available:after {
      background: #404a2f;
    }

    &.selected:after {
      background: #278b96;
    }
  }
}

.legenda {
  background: transparent;
  z-index: 50;
  position: absolute;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: auto!important;
  flex-direction: column;
  margin: 2rem 0 -5rem 0rem;
  p {
    flex-basis: 100%;
    font-size: 0.9rem;
    margin: 0.1rem 0.5rem;
    line-height: 1.5;
    text-transform: uppercase;
    &:before {
      content: "";
      width: 20px;
      height: 20px;
      display: inline-block;
      margin: 0 0.5rem;
      position: relative;
      top: 0.3rem;
      border-radius: 50%;
    }
    &.available:before {
      background: $floor-color;
    }
    &.not-available:before {
      background: $floor-color-na;
    }
    &.selected:before {
      background: $floor-color-sc;
    }
  }
}

.quote-number-container.floor-page {
  bottom: 210px!important;
  left: unset!important;
  position: initial!important;
}

// .button--indietro {
//   position: fixed;
//   bottom: 65%;
//   right: 24%;

.button--indietro {
  position: absolute;
  bottom: 94%;
  right: 20%;

  @media screen and (max-width: $small-desktop) {
    bottom: 15%;
    right: 50%;
    transform: translateX(50%);
  }
}

// .button--riepilogo {
//   position: fixed;
//   bottom: 65%;
//   right: 10%;

.button--riepilogo {
  position: absolute;
  bottom: 94%;
  right: 5%;

  @media screen and (max-width: $small-desktop) {
    bottom: 5%;
    right: 50%;
    transform: translateX(50%);
  }
}

@media screen and (max-width: 1500px) {
  .building-container{
    width: 850px;
}
.building-container:after {
  top: -183px;
}
}
@media screen and (max-width: 1300px) {
  .building-container{
    width: 750px;
}
.building-container:after {
  top: -187px;
  width: 160%;
}
}
@media screen and (max-width: 1100px) {
  .building-container{
    min-width: 700px;
    width: 100%;
    padding: 0 5rem;
}
.building-container:after {
  display: none;
}
  .building-map__section.main-wrapper{
    overflow-x: scroll;
    white-space: nowrap;
  }

.building-container .building-plan:after{
  right: inherit;
  left: -2rem;
}

}

.building-map {
  .building-map__section.main-wrapper {
    min-height: 756px;
  }
}

.building-map .building-map__section.views-wrapper .views-wrapper__plan {
  justify-content: initial!important;
  width: auto;
  margin-left: 20px!important;

  .plan-header {
    span {
      margin: 0 0 0 2.2rem!important;
      &:first-child {
        margin: 0 0 0 3rem!important;
      }
    }
  }
}

.views-wrapper .view-icon.empty-block {
  background-color: transparent!important;
  cursor: default;
}

.floor-section.building-container .prospettiSmallModelWrapper {
	content: "" !important;
	background-size: contain !important;
	background-repeat: no-repeat !important;
	display: block !important;
	width: 160px !important;
	height: 160px !important;
	position: absolute !important;
	top: -1rem !important;
	right: 0 !important;
}
