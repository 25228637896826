.das-wrapper {
  .building-quadrant {
    height: 30px;
    // background: $floor-color-na;
    background: #6C6C6C;
    color: #fff;
    text-align: center;
    font-size: 1rem;
    line-height: 2.8rem;
    box-shadow: inset 5px 5px 0px $background-color;
    position: relative;
    &:before {
      content: "";
      @include icon(15px, no-repeat, center bottom, 100%, 100%);
      display: flex;
      position: absolute;
      bottom: 0;
      left: 0;
      border-right: 5px solid #fff;
    }
    &.fixed-place {
      background-color: #333 !important;
      color: #fff !important;
      z-index: 2;

      &:before,
      &:after {
        content: unset !important;
      }
    }
    &:after {
      content: "";
      width: calc(100% - 5px);
      height: 40%;
      background-color: rgba($black, 0.1);
      display: flex;
      position: absolute;
      bottom: 0;
      left: 5px;
      z-index: 0 !important;
      border-right: 5px solid #fff;
    }
    &.available {
      background: $floor-color;
      cursor: pointer;
    }
    &.active {
      background-color: $floor-color-sc;
    }
    &[id*="Z"] {
      background: #cccccc;
      //background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 4%, rgba(255,249,158,1) 100%);
      opacity: 1;
      &:after {
        content: none;
      }
    }
    // &:hover{
    //   background-color: crimson;
    // }
  }

  .building-quadrant-plan {
    height: 30px;
    border-right: 5px solid #fff;
    // background: $floor-color-na;
    background:#31AE79;
    color: #fff;
    text-align: center;
    font-size: 1rem;
    line-height: 2.8rem;
    box-shadow: inset 5px 5px 0px $background-color;
    position: relative;
    cursor: pointer;

    &.cross-floor {
      background: transparent!important;
      cursor: default!important;
      box-sizing: border-box;
      padding-left: 2px;
      margin-right: 0px;
      padding-top: 0px;
      border-right: none;
      box-shadow: none;
      
      polygon {
        stroke: white!important;
        fill: #31AE79;
      }
    }

    &:before {
      content: "";
      @include icon(15px, no-repeat, center bottom, 100%, 100%);
      display: flex;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    &.fixed-place {
      background-color: #333 !important;
      color: #fff !important;
      z-index: 2;

      &:before,
      &:after {
        content: unset !important;
      }
    }
    &:after {
      content: "";
      width: calc(100% - 5px);
      height: 40%;
      background-color: rgba($black, 0.1);
      display: flex;
      position: absolute;
      bottom: 0;
      left: 5px;
      z-index: 0 !important;
    }
    &.available {
      background: $floor-color;
      cursor: pointer;
    }
    &.not-available {
      background: #404a2f;
      cursor: pointer;
    }
    &.active {
      background-color: $floor-color-sc;
    }
    &[id*="Z"] {
      background: #cccccc;
      //background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 4%, rgba(255,249,158,1) 100%);
      opacity: 1;
      &:after {
        content: none;
      }
    }
    &:hover{
      background-color:#143D21;
    }
  }

  .building-quadrant.active {
    background:black;
  }

  .building-quadrant.not-available {
    background: #da1845;
  }

  .building-quadrant.not-available-actual {
    background: #B7AFA4;
  }

  .quadrants-container {
    display: flex;
    flex-basis: 100%;
  }

  .container-arrow .arrow-up.forward {
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    cursor: pointer;
    border-top: 25px solid #143D21;
    transform: rotate(270deg);
    position: absolute;
    top: 40%;
    right: 30%;
  }

  .container-arrow .arrow-down.back{
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    cursor: pointer;
    transform: rotate(90deg);
    border-top: 25px solid #143D21;
    position: absolute;
    top: 40%;
    left: 30%;
  }

  .container-arrow .arrow-up.forward.sides {
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    color: #31AE79;
    cursor: pointer;
    border-top: 25px solid #31AE79;
    transform: rotate(0deg);
    position: absolute;
    top: 95%;
    left: 49%;
  }

  .container-arrow .arrow-down.back.sides{
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    color: #31AE79;
    cursor: pointer;
    transform: rotate(180deg);
    border-top: 25px solid #31AE79;
    position: absolute;
    top: 7%;
    left: 49%;
  }

  .arrow-change-side-text {
    font-size: 15px;
    position: absolute;
    margin-top: -30px;
    margin-left: 30px;
  }

  .arrow-change-side-text.up {
    font-size: 15px;
    position: absolute;
    margin-top: -30px;
    margin-left: 30px;
    transform: rotate(180deg);
  }

  .arrow-change-building-text.right {
    font-size: 15px;
    position: absolute;
    margin-top: -7px;
    margin-left: -91px;
    transform: rotate(90deg);
  }

  .arrow-change-building-text.left {
    font-size: 15px;
    position: absolute;
    margin-top: -35px;
    margin-left: 22px;
    transform: rotate(270deg);
  }

  .button--choose-appartment {
    background-color: #143D21 !important;
    color: #31AE79 !important;
    border-radius: 0px !important;
    bottom: 94% !important;
    right: 0% !important;
    box-shadow: none !important;
    font-family: montserrat, sans-serif !important;
    position: absolute;
  }

  .building-name {
    color: #143D21;
  }

  .building-container .building-plan .building-block-das {
    height: 30px;
    flex-basis: 100%;
    color: #fff;
    text-align: center;
    font-size: 1rem;
    line-height: 2.8rem;
    position: relative;
    // background: #d8dad5;
    // box-shadow: inset 5px 5px 0px #fff;
    // border-right: 5px solid #fff;

    &.empty-floor {
      background: #6c6c6c;
      box-shadow: inset 5px 5px 0px #fff;
      border-right: 5px solid #fff;
      flex-basis:100%;
      margin-right: -4px;
    }
  }

  .floor-section.building-container .single-building {
    width: 80%;
    max-width: 80%;
    display: flex;
    align-items: center;
  }

  .floor-section.building-container .single-building .blocks-container-das {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 50%;
  }

  .quadrant-code{
    color: white;
    position: absolute;
    right: 1%;
    line-height: 1rem;
    bottom: 0;
    z-index: 1;
    &.partially-showed {
      font-size: 12px;
    }
  }

  .button--indietro.aptdas {
    position: absolute;
    left: 5%;
    top: 24%;
    height: 45px;
  }

  .quadrants-wrapper {
    background-color: chartreuse !important;
    display: flex !important;
  }

  .building-floor {
    display: flex;
  }

  .side-wrapper {
    background-color: black
  }

  .affaccio-title {
    color: #31AE79;
  }

  .back-button {
    right: -2%;
  }

  .building-quadrant-plan.transparent {
    background-color: transparent;
    box-shadow: none;
    border-right: none;
    pointer-events: none;

    .quadrant-code-fixed {
      display: none;
    }

    &:after {
      content: unset !important;
    }
  }

  // LEGENDA
  .legenda .legendaContainer {
    p {
      // blocco quadrante
      &.das-quadrant:after {
        background: rgb(108, 108, 108);
      }

      // blocco planimetria
      &.das-available:after {
        background: rgb(49, 174, 121);
      }

      &.das-proposte:after {
        background: red;
      }

      // blocco planimetria gia acquistata (non disponibile)
      &.das-not-available:after {
        background: #404a2f;
      }
    }
  }

  .building-container .building-plan .building-block-das.empty-floor {
    background: rgb(108, 108, 108);
  }

  .building-quadrant {
    &.das-quadrant-available {
      background: rgb(108, 108, 108);
    }
  }

  .building-quadrant-plan.cross-floor {
    &.das-available polygon {
      fill: rgb(49, 174, 121);
      stroke: rgb(49, 174, 121);
      cursor: pointer!important;

      &:hover {
        fill: rgb(20, 61, 33);
        stroke: rgb(20, 61, 33);
      }
    }

    &.das-proposte polygon {
      fill: red;
      stroke: red;
    }

    &.das-not-available polygon {
      fill: #404a2f;
      stroke: #404a2f;
      cursor: default;
    }
  }

  .building-quadrant-plan {
    &.das-available {
      background: rgb(49, 174, 121);

      &:hover {
        background: rgb(20, 61, 33);
      }
    }

    &.das-proposte {
      background: red;
    }

    &.das-not-available {
      background: #404a2f;
      cursor: default;
    }
  }
}

.button--indietro.aptdas {
  position: absolute;
  right: 5%;
  top: 22%;
  height: 50px;
}