#floorplanning-app {
  // UTILITIES
  @import 'utilities/_mixins.scss'; // Mixins
  @import 'utilities/_animations.scss'; // Animations

  // CUSTOMIZATION
  @import 'customization/_config.scss'; // CONFIG

  // BASE
  @import 'base/_reset.scss'; // CSS Reset
  @import 'base/_base.scss'; // Base
  @import 'base/_typography.scss'; // Typography
  @import 'base/_images.scss'; // Images

  // COMPONENTS
  @import 'components/_buttons.scss'; // Buttons
  @import 'components/_modal.scss'; // Modal
  @import 'components/_offcanvas-cart.scss'; // Offcanvas Cart
  @import 'components/_edit-nav.scss'; // Edit NAV
  @import 'components/_slider-gallery.scss'; // Slider gallery
  @import 'components/_virtual-keyboard.scss'; // Virtual keyboard
  @import 'components/_select.scss'; // Select/Dropdown

  // LAYOUT
  @import 'layout/_header.scss'; // Header
  @import 'layout/_nav.scss'; // NAV
  @import 'layout/_grid.scss'; // Grid
  @import 'layout/_sidebar.scss'; // Sidebar
  @import 'layout/_footer.scss'; // Footer

  // PAGES
  @import 'pages/_page-login.scss'; // Page Login
  @import 'pages/_page-planimetry.scss'; // Page Planimetry
  @import 'pages/_page-floor.scss'; // Page Plan
  @import 'pages/_page-das.scss'; // Page Das
  @import 'pages/_page-building.scss'; // Page Building
  @import 'pages/_page-riepilogo.scss'; // Page Riepilogo
  @import 'pages/_page-upselling.scss'; // Page Upselling
  @import 'pages/_page-preventivo.scss'; // Page Preventivo

  /**
   It is necessary to import the ErrorPage CSS from tecma/ds because the floorplanning CSS overrides the ErrorPage CSS incorrectly.
   This is a temporary fix; the real solution is to rewrite the floorplanning SCSS entirely.
  */
  @import '@tecma/ds/lib/views/ErrorPage/errorPage.scss';
  .errorPage-errorMsg {
    color: #3b3b3b;
  }
}

@import 'utilities/_bodyStyle.scss'; //Body style
