@use "../../theme/utils/variables" as *;

.tecma-input {
  display: flex;
  gap: 0.125rem 0;

  &.error-label-up {
    flex-direction: column;
  }

  &.error-label-down {
    flex-direction: column-reverse;
  }

  .input-group {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 0.625rem;
    border: 0.063rem solid $primary;
    border-radius: 0.125rem;
    background-color: $neutral-100;
    transition: all 0.3s ease;

    &:hover {
      border-color: $accent;
    }

    &:focus-within {
      border-color: $accent;
      box-shadow: 0 0 0 0.125rem rgba($accent, $alpha: 0.5);
    }

    input {
      width: 100%;
      border: none;
      outline: none;
      padding: 0.45rem 0;
      font-size: inherit;

      & + label {
        position: absolute;
        color: $semantic-disabled-dark;
        top: 50%;
        left: 0.625rem;
        pointer-events: none;
        transform: translateY(-50%);
        transition: 0.3s all ease;
      }

      &.is-active,
      &.without-placeholder {
        padding-top: 0.75rem;
        padding-bottom: 0.15rem;
      }

      &.is-active + label {
        font-size: $extraSmallFont;
        color: $accent;
        top: 1%;
        transform: translateY(0%);
      }
    }

    .arrow-steps {
      display: flex;
      flex-direction: column;
      align-self: stretch;

      .arrow-button {
        height: 100%;

        .tecma-icon {
          height: 100%;
          width: 100%;
        }
      }
    }

    input[type="number"] {
      appearance: textfield;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }
  }

  &.error {
    .error-label {
      color: $semantic-alert;
    }

    .input-group {
      border-color: $semantic-alert;

      &:focus-within {
        border-color: $semantic-alert;
        box-shadow: 0 0 0 0.125rem rgba($semantic-alert, $alpha: 0.5);
      }
    }
  }

  &.warning {
    .error-label {
      color: $semantic-warning;
    }

    .input-group {
      border-color: $semantic-warning;

      &:focus-within {
        border-color: $semantic-warning;
        box-shadow: 0 0 0 0.125rem rgba($semantic-warning, $alpha: 0.5);
      }
    }
  }

  &.disabled {
    .input-group {
      color: $semantic-disabled;
      background-color: $semantic-disabled-light;
      border-color: $semantic-disabled;

      .input-icon {
        pointer-events: none;
      }
    }
  }

  &.small {
    font-size: $smallFont;
    width: 8.875rem;

    .input-group {
      padding: 0 0.375rem;

      &.with-icon {
        padding-right: 0;
      }

      .input-icon {
        padding: 0 0.375rem;
      }

      .arrow-steps {
        .arrow-button {
          .tecma-icon {
            height: 80%;
            width: 80%;
          }
        }
      }

      input {
        padding: 0.375rem 0;

        & + label {
          left: 0.375rem;
        }

        &.is-active {
          padding-top: 0.55rem;
          padding-bottom: 0.05rem;
        }

        &.is-active + label {
          font-size: 0.5rem;
        }
      }
    }
  }

  &.medium {
    font-size: $defaultFont;
    width: 10.5rem;

    .input-group {
      &.with-icon {
        padding-right: 0;
      }

      .input-icon {
        padding: 0 0.625rem;
      }
    }
  }

  &.large {
    font-size: $largeFont;
    width: 14.75rem;

    .input-group {
      padding: 0 0.75rem;

      &.with-icon {
        padding-right: 0;
      }

      .input-icon {
        padding: 0 0.75rem;
      }

      input {
        padding: 0.45rem 0;

        & + label {
          left: 0.75rem;
        }

        &.is-active {
          padding-top: 0.75rem;
          padding-bottom: 0.15rem;
        }
      }
    }
  }
}
