.link-cms {
  color: #7c7c7c;
  border-bottom: 1px solid #7c7c7c;
  cursor: pointer;
  display: flex;
  align-items: center;

  &.no-underline {
    border-bottom: none;
  }
}
