.icon {
  background-size: 20px;
  padding: 2px;
  background-position: center;
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;

  &.small {
    width: 15px;
    height: 15px;
    background-size: 10px;
  }

  &.medium {
    width: 30px;
    height: 30px;
    background-size: 18px;
  }

  &.large {
    width: 40px;
    height: 40px;
    background-size: 30px;
  }
}
