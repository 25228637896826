@import '../vars';
@import '../../common/mixin.scss';
@import './../../common/config.scss';

.cms-select-tiles-page {
  position: fixed;
  top: 72px;
  left: 0;
  width: 100%;
  height: calc(100% - 72px);
  z-index: 1299;
  background-color: white;

  .content-select-tiles {
    padding: 40px 56px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .info-iniziativa {
      .nome-iniziativa {
        @include text($color: $mainTextColor, $fontSize: 46px, $fontWeight: 300);
      }

      .payoff-iniziativa {
        margin-top: 15px;
        @include text($color: $secondaryTextColor, $fontWeight: 300, $fontSize: 22px);
      }
    }

    .info-select-tiles {
      margin-top: 50px;
      @include text($color: $mainTextColor, $fontWeight: 400, $fontSize: 16px);
    }

    .tiles-templates {
      margin-top: 30px;

      .tiles-template {
        display: inline-block;
        padding: 20px 30px;
        cursor: pointer;

        &:not(:first-child) {
          margin-left: 5px;
        }

        .tiles-name {
          text-align: center;
          margin-top: 13px;
          @include text($color: #848484, $fontSize: 14px, $fontWeight: 600);
        }

        &.selected {
          box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.08);
          border-radius: 4px;
          .tiles-name {
            color: #1c8576;
            font-weight: 700;
          }

          .single-element {
            background-color: #909090;
          }
        }
      }

      .second-row {
        margin-top: 30px;
      }

      .tiles-container {
        display: grid;
        height: 120px;
        width: 120px;
        row-gap: 2px;
        column-gap: 2px;

        .single-element {
          background-color: $tertiaryBackgroundColor;
          border-radius: 1px;
        }

        // 4 tiles
        &.elements-4 {
          .el-0 {
            grid-area: 1 / 1 / 2 / 2;
          }
          .el-1 {
            grid-area: 1 / 2 / 2 / 3;
          }
          .el-2 {
            grid-area: 2 / 1 / 3 / 2;
          }
          .el-3 {
            grid-area: 2 / 2 / 3 / 3;
          }
        }

        // 5 tiles
        &.elements-5 {
          .el-0 {
            grid-area: 1 / 1 / 2 / 2;
          }
          .el-1 {
            grid-area: 1 / 2 / 2 / 3;
          }
          .el-2 {
            grid-area: 2 / 1 / 3 / 2;
          }
          .el-3 {
            grid-area: 2 / 2 / 3 / 3;
          }
          .el-4 {
            grid-area: 3 / 1 / 4 / 3;
          }
        }

        // 6 tiles
        &.elements-6 {
          .el-0 {
            grid-area: 1 / 1 / 2 / 2;
          }
          .el-1 {
            grid-area: 1 / 2 / 2 / 3;
          }
          .el-2 {
            grid-area: 2 / 1 / 3 / 2;
          }
          .el-3 {
            grid-area: 2 / 2 / 3 / 3;
          }
          .el-4 {
            grid-area: 3 / 1 / 4 / 2;
          }
          .el-5 {
            grid-area: 3 / 2 / 4 / 3;
          }
        }

        // 7 tiles
        &.elements-7 {
          .el-0 {
            grid-area: 1 / 1 / 2 / 2;
          }
          .el-1 {
            grid-area: 1 / 2 / 2 / 3;
          }
          .el-2 {
            grid-area: 1 / 3 / 2 / 4;
          }
          .el-3 {
            grid-area: 2 / 1 / 3 / 2;
          }
          .el-4 {
            grid-area: 2 / 2 / 3 / 3;
          }
          .el-5 {
            grid-area: 2 / 3 / 3 / 4;
          }
          .el-6 {
            grid-area: 3 / 1 / 4 / 4;
          }
        }

        // 8 tiles
        &.elements-8 {
          .el-0 {
            grid-area: 1 / 1 / 2 / 2;
          }
          .el-1 {
            grid-area: 1 / 2 / 2 / 3;
          }
          .el-2 {
            grid-area: 2 / 1 / 3 / 2;
          }
          .el-3 {
            grid-area: 2 / 2 / 3 / 3;
          }
          .el-4 {
            grid-area: 3 / 1 / 4 / 2;
          }
          .el-5 {
            grid-area: 3 / 2 / 4 / 3;
          }
          .el-6 {
            grid-area: 4 / 1 / 5 / 2;
          }
          .el-7 {
            grid-area: 4 / 2 / 5 / 3;
          }
        }

        // 9 tiles
        &.elements-9 {
          .el-0 {
            grid-area: 1 / 1 / 2 / 2;
          }
          .el-1 {
            grid-area: 1 / 2 / 2 / 3;
          }
          .el-2 {
            grid-area: 1 / 3 / 2 / 4;
          }
          .el-3 {
            grid-area: 2 / 1 / 3 / 2;
          }
          .el-4 {
            grid-area: 2 / 2 / 3 / 3;
          }
          .el-5 {
            grid-area: 2 / 3 / 3 / 4;
          }
          .el-6 {
            grid-area: 3 / 1 / 4 / 2;
          }
          .el-7 {
            grid-area: 3 / 2 / 4 / 3;
          }
          .el-8 {
            grid-area: 3 / 3 / 4 / 4;
          }
        }

        // 10 tiles
        &.elements-10 {
          .el-0 {
            grid-area: 1 / 1 / 2 / 2;
          }
          .el-1 {
            grid-area: 1 / 2 / 2 / 3;
          }
          .el-2 {
            grid-area: 1 / 3 / 2 / 4;
          }
          .el-3 {
            grid-area: 2 / 1 / 3 / 2;
          }
          .el-4 {
            grid-area: 2 / 2 / 3 / 3;
          }
          .el-5 {
            grid-area: 2 / 3 / 3 / 4;
          }
          .el-6 {
            grid-area: 3 / 1 / 4 / 2;
          }
          .el-7 {
            grid-area: 3 / 2 / 4 / 3;
          }
          .el-8 {
            grid-area: 3 / 3 / 4 / 4;
          }
          .el-9 {
            grid-area: 4 / 1 / 5 / 4;
          }
        }

        // 11 tiles
        &.elements-11 {
          .el-0 {
            grid-area: 1 / 1 / 2 / 2;
          }
          .el-1 {
            grid-area: 1 / 2 / 2 / 3;
          }
          .el-2 {
            grid-area: 1 / 3 / 2 / 4;
          }
          .el-3 {
            grid-area: 2 / 1 / 3 / 2;
          }
          .el-4 {
            grid-area: 2 / 2 / 3 / 3;
          }
          .el-5 {
            grid-area: 2 / 3 / 3 / 4;
          }
          .el-6 {
            grid-area: 3 / 1 / 4 / 2;
          }
          .el-7 {
            grid-area: 3 / 2 / 4 / 3;
          }
          .el-8 {
            grid-area: 3 / 3 / 4 / 4;
          }
          .el-9 {
            grid-area: 4 / 1 / 5 / 2;
          }
          .el-10 {
            grid-area: 4 / 2 / 5 / 4;
          }
        }

        // 12 tiles
        &.elements-12 {
          .el-0 {
            grid-area: 1 / 1 / 2 / 2;
          }
          .el-1 {
            grid-area: 1 / 2 / 2 / 3;
          }
          .el-2 {
            grid-area: 1 / 3 / 2 / 4;
          }
          .el-3 {
            grid-area: 2 / 1 / 3 / 2;
          }
          .el-4 {
            grid-area: 2 / 2 / 3 / 3;
          }
          .el-5 {
            grid-area: 2 / 3 / 3 / 4;
          }
          .el-6 {
            grid-area: 3 / 1 / 4 / 2;
          }
          .el-7 {
            grid-area: 3 / 2 / 4 / 3;
          }
          .el-8 {
            grid-area: 3 / 3 / 4 / 4;
          }
          .el-9 {
            grid-area: 4 / 1 / 5 / 2;
          }
          .el-10 {
            grid-area: 4 / 2 / 5 / 3;
          }
          .el-11 {
            grid-area: 4 / 3 / 5 / 4;
          }
        }
      }
    }

    .container-buttons {
      @include flexContainer();
      flex-grow: 1;
      align-items: flex-end;
      margin-top: 40px;
      flex-wrap: wrap;
      justify-content: flex-end;
      padding-right: 56px;

      .button-cms {
        font-size: 14px;
        padding: 16px 40px;
      }

      .button-crea {
        margin-left: 16px;
      }
    }
  }
}

.modal-remove-tiles {
  @include flexContainer($direction: column);

  .card-to-delete {
    padding: 5px 15px;
    margin: 0 auto;
    margin-top: 10px;
    height: 40px;
    width: auto;
    @include text($color: #909090, $fontSize: 14px, $fontWeight: 400);
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }

  .cards-container {
    margin-top: 40px;
    @include flexContainer($flex-wrap: wrap, $justify-content: space-between);

    .single-card {
      @include flexContainer($align-items: center);
      width: 45%;
      @include text($color: black, $fontSize: 14px, $fontWeight: 400);
      background-color: #e5e5e5;
      padding: 20px 15px;
      margin-bottom: 10px;
      border-radius: 5px;
      cursor: pointer;
      position: relative;

      &.selected {
        &:before {
          content: '';
          border-radius: 5px;
          background-color: rgba(24, 24, 24, 0.7);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  }
}
