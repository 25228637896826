//$preventivo-color-dark: darken(desaturate($secondary-color, 30%), 15%);
$preventivo-color-dark: $tertiary-color;
//$preventivo-color-light: desaturate($secondary-color, 35%);
$preventivo-color-light: $primary-color;

$preventivo-dx-color-dark: lighten(desaturate($primary-color, 50%), 30%);
$preventivo-dx-color-light: lighten(desaturate($primary-color, 50%), 50%);
$myHomeDialogue: '.quotation-sx .quotation-container .my-home-dialogue .dialogue';

// input
.quotation-container select,
.quotation-container input {
  background-color: transparent;
  width: 90%;
  height: 25px;
  border: 0;
  border-bottom: 1px solid $preventivo-color-light;
  margin: 0 1rem 0 0;
  min-width: 80px;
  padding: 0;

  &:focus {
    outline: none;
    border-color: $tertiary-color;
  }
}

.quotation-container .add-line,
.quotation-container .remove-line {
  background: $primary-color;
  color: $white;
  text-align: center;
  line-height: 1.5;
  height: 18px;
  width: 18px;
  position: absolute !important;
  top: -10px;
  left: -27px;
  border: 1px solid $white;
  border-radius: 50%;
  box-shadow: 0 0 0 1px $primary-color;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;

  &:hover {
    transform: rotate(-90deg);
    box-shadow: 0 0 0 2px $tertiary-color;
  }
}

.quotation-container .add-line {
  line-height: 1.3;
  top: 0;
  left: -13px;
}

.quotation-container .remove-line {
  background: $success-color;
  box-shadow: 0 0 0 1px $success-color;
  transition: box-shadow 0.3s;
  position: absolute;
  cursor: pointer;

  &:hover {
    transform: none;
    box-shadow: 0 0 0 2px $success-color;
  }
}

.quotation-name {
  background-color: $preventivo-color-light;
  color: $white;
  font-weight: bold;
  padding: 0.5rem 1rem 1rem 1rem;
  box-sizing: border-box;
  position: absolute;
  top: -34px;
  left: 0;
  border-radius: 10px 10px 0 0;
}

.quotation-sx .quotation-name {
  top: 1rem;
  left: 2rem;
}

.quotation-container > div * {
  font-size: 0.85rem;
}

.quotation-container > div h6 {
  color: $primary-color;
  position: relative;

  &:before {
    content: '';
    background-color: $primary-color;
    width: 1.5rem;
    height: 3px;
    display: block;
    position: absolute;
    left: -2rem;
    top: 7px;
  }
}

.quotation-sx .quotation-container form .pagamenti {
  height: 0;
}

span.note,
sup.note {
  font-size: 0.6rem !important;
  position: relative;
  top: -7px;
  left: 1px;
}

.price.big sup.note {
  font-size: 1.3rem !important;
  font-weight: normal;
  top: -20px;
}

.plan-info__title-modal sup.note {
  font-size: 1rem !important;
  top: -20px;
}

.disclaimer {
  font-family: $primary-font;
  font-size: 0.6rem;
  text-transform: none;
  border-top: 0;
  margin-bottom: -20px;
}

.disclaimer.quote {
  margin-left: 0px !important;
}

.quotation-dx {
  .disclaimer {
    font-family: $primary-font;
    font-size: 0.5rem;
    text-transform: none;
    margin: 1rem 0px;
    width: 100%;
    border-top: 0;
    margin-top: -5px;
    margin-bottom: -3px;

    ul {
      padding-left: 0px;
    }
  }
}

.quotation-container > div > div,
.quotation-container .block > div > div {
  font-size: 0.9rem;
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin: 0.1rem 0;
  padding: 0.2rem 0 0.2rem 0;
  border-bottom: 1px solid $preventivo-color-light;
  box-sizing: border-box;
  position: relative;
}

.quotation-container > div:not(.block) > div > div {
  flex: 0 0 40%;
}

.quotation-container > div > div > .data {
  flex: 1 0 30% !important;
  text-align: right;
}

.quotation-container > div .data span {
  color: $tertiary-color;
}

.quotation-container > div .data span.piano {
  color: $text-color;
  margin-left: 1rem;
}

.quotation-container > div .data span.piano b {
  color: $tertiary-color;
}

.quotation-container > div > div > span {
  font-weight: normal;
  text-align: right;
  text-transform: uppercase;
  flex: 1 0 20%;
}

.quotation-container > div:not(.block),
.quotation-container .block > div {
  background-color: rgba($white, 0.9);
  margin: 0 0 0.5rem 0;
  padding: 1rem 2rem;
}

.quotation-container select {
  position: relative;
  top: 1px;
}

.quotation-container label {
  color: $preventivo-color-light;
  font-weight: normal;
  position: absolute;
  bottom: -17px;
  left: 0;
}

// DIALOG
.quotation-container .block > div .dialogue {
  background-color: $preventivo-color-dark;
  width: 350px;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 2rem !important;
  display: flex;
  flex-wrap: wrap;
  border-radius: 0;
  @extend %center-absolute-XY;
  z-index: 9;

  &:before {
    content: '';
    background-color: rgba($black, 0.3);
    width: 150vw;
    height: 500vh;
    position: fixed;
    top: -1000%;
    z-index: -1;
  }
}

.quotation-container .block.block-bottom > div .dialogue {
  top: 0;
  transform: translate(-50%, -100%);

  &:before {
    top: -500%;
  }
}

.quotation-container .block > div .dialogue h4 {
  color: $white;
  font-size: 0.9rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
}

.quotation-container .block > div .button {
  background-color: $preventivo-dx-color-dark;
  color: white !important;
  width: auto;
  margin: 0.5rem;
  border-radius: 0;
}

.quotation-container .block > div > div.send-for-enable-myhome {
  font-family: $primary-font;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  border: none;
  justify-content: center;
}

// QUOTATION SX
.quotation-sx {
  background-color: $preventivo-color-dark;
  display: flex;
  flex-wrap: wrap;
  width: 33%;
  min-width: 600px;
  height: 100vh;
  min-height: 100px;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 4;
}

.quotation-sx .side-button {
  background-color: $primary-color;
  color: $tertiary-color;
  height: 50px;
  width: 50px;
  display: block;
  position: absolute;
  top: 10%;
  right: -18px;
  border-radius: 0 25px 25px 0;
  transition: right 0.3s;
  cursor: pointer;
  box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.16);

  &:hover {
    border-left: 5px solid $primary-color;
    right: -23px;

    &::after {
      transform: rotate(90deg);
      transition: transform 0.3s;
    }
  }
}

.quotation-sx .plan-image:before {
  content: none;
}

.quotation-sx .plan-image {
  background-size: contain;
}

.quotation-sx .side-button:after {
  content: '';
  @include icon(contain, no-repeat, center, 30px, 35px);
  display: inline-block;
  position: relative;
  left: 15px;
  top: -6px;
}

.quotation-sx .side-button.button-ruota:after {
  @include icon(contain, no-repeat, center, 26px, 35px);
  top: -6px !important;
}

.quotation-sx .side-button.button-ruota {
  top: calc(10% + 60px);
}

.quotation-sx .side-button.button-reset:hover:after {
  transform: none;
  left: 20px;
  transition: left 0.4s;
}

.quotation-sx .side-button.button-reset {
  top: calc(10% + 120px);
}

.quotation-sx .side-button span {
  font-size: 10px;
  display: block;
  transform: rotate(-90deg);
  transform-origin: 22px 20px;
}

.quotation-exit {
  background-color: $primary-color;
  width: 220px;
  padding: 1rem;
  position: absolute;
  right: -189px;
  top: 30%;
  border-radius: 0 10px 10px 0;
  box-sizing: border-box;
  box-shadow: 3px 3px 3px rgba($black, 0.2);
}

.quotation-exit h4 {
  color: $tertiary-color;
  font-size: 0.9rem;
  font-weight: normal;
  text-align: center;
}

.quotation-exit .primary-button {
  background-color: lighten($primary-color, 25%);
  color: $white;
  font-size: 0.9rem;
  margin: 0.3rem 0;
  width: 100%;
  border-radius: 0;
  box-sizing: border-box;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.9;
  }
}

.block.block-top {
  width: 100%;
}

.quotation-sx .quotation-container {
  background-color: $preventivo-color-light;
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  position: relative;
  border-radius: 10px;
  box-shadow: 3px 3px 3px rgba($black, 0.2);
}

.quotation-container .quotation-name {
  background-color: $preventivo-color-light;
  color: $white;
  font-weight: bold;
  padding: 0.5rem 1rem 1rem 1rem;
  box-sizing: border-box;
  position: absolute;
  top: -34px;
  left: 0;
  border-radius: 10px 10px 0 0;
}

// dotazioni
.quotation-sx .quotation-container .dotazione-row {
  display: flex;
  padding: 0 0 1.2rem 0;
  border-bottom: 0;
}

.quotation-sx .quotation-container .dotazione-row > div {
  flex: 1 0 auto;
  position: relative;
  margin-top: 1.2rem;
}

.quotation-sx .quotation-container .dotazione-row > div:last-child input {
  margin: 0;
}

.quotation-sx .quotation-container .dotazione-row div.amn_type_selector_container {
  //flex-basis: 15%;
  margin-right: 5px;
}

.quotation-sx .quotation-container .dotazione-row div:nth-child(3) {
  //flex-basis: 15%;
  margin-right: 1rem;
}

.quotation-sx .quotation-container .dotazione-row {
  padding-left: 15px !important;

  .remove-line {
    line-height: 1.2;
    left: -20px !important;
    background-color: #e07e63;
    border: 1px solid white !important;
    box-shadow: 0 0 0 1px #e07e63 !important;
  }

  .MuiSwitch-root {
    position: absolute !important;
    top: -10px;
    left: -40px;

    .MuiButtonBase-root {
      padding: 9px !important;
    }
  }
}

.quotation-dx .quotation-container .data {
  text-align: left;
}

// pagamenti
.quotation-sx .quotation-container .pagamenti > div {
  display: flex;
  padding: 0 0 1.3rem 0;
  border-bottom: 0;
}

.quotation-sx .quotation-container .pagamenti > div > div {
  flex: 1 0 33%;
  position: relative;
}

.quotation-sx .quotation-container .pagamenti .name {
  flex-basis: 30%;
  position: relative;
  top: 5px;
}

.quotation-sx .quotation-container .dotazioni .dotazione-row .remove-line {
  left: -30px !important;
  top: 2px;
}

.mutuo input[type='int'] {
  width: 30% !important;
  margin: 0 0 0 0 !important;
  min-width: unset !important;
}

.mutuo .MuiSwitch-thumb {
  margin-top: 4px !important;
}

.mutuo span {
  display: flex !important;
  justify-content: flex-end !important;
}

// planimetry
.quotation-sx .riepilogo__section {
  height: inherit;
  margin: 0;
}

.quotation-sx .riepilogo__section.section-planimetry .plan-image {
  min-height: 250px;
  background-position: center;
  border: 0;
  background-size: contain !important;
}

.quotation-sx .riepilogo__section.section-planimetry .plan-image__button {
  position: absolute;
  left: -20px;
  top: -7px;
}

.quotation-sx .secondary-button.toggle-image {
  background-color: $tertiary-color;
  color: $primary-color;
  text-align: center;
  text-transform: uppercase;
  margin: -0.5rem auto 1rem auto;
  padding: 0.7rem 2rem 0.7rem 2rem;
  display: block;
  float: right;
  width: 150px;
}

.slide {
  background-position: 75% 0%;
  max-height: 90%;
  margin-top: 2%;
  box-sizing: border-box;
  background-repeat: no-repeat;
}

.SliderButton.prevArrow {
  left: 25%;
}

.quotation-sx .buttons .button-myhome_tipology {
  margin-left: 4rem;
}

// buttons
.quotation-sx .quotation-container .buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.quotation-sx .quotation-container .buttons .button {
  background-color: $primary-color;
  color: $tertiary-color;
  text-transform: uppercase;
  border-radius: 0;
  min-width: 220px;
  transition: background-color 0.3s, color 0.3s;
  margin: 0;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;

  &:hover {
    background-color: $tertiary-color;
    color: $primary-color;
  }
}

.block-myhome-dialogue {
  width: 100%;
  position: absolute;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.3) !important;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0 !important;
  margin: 0 !important;

  .my-home-dialogue-container {
    height: auto !important;
    width: 100% !important;
    position: absolute;
    bottom: 150px;
    left: 0 !important;
    transform: unset !important;
    background-color: unset !important;
    border: unset !important;
  }

  .dialogue {
    background-color: $preventivo-color-dark;
    width: 400px !important;
    padding: 2rem !important;
    display: flex;
    flex-wrap: wrap;
    border-radius: 0;
    z-index: 9;
    bottom: 5%;
    top: unset !important;
    transform: translateX(-50%) !important;

    button {
      width: auto !important;
    }

    &:before {
      top: 0 !important;
      left: 0 !important;
      height: 100% !important;
      width: 100% !important;
    }

    form {
      width: 100%;

      .radio {
        display: flex !important;
        margin-left: 20px !important;

        input {
          width: auto;
        }
      }
    }

    .inline-buttons {
      display: flex;
      justify-content: space-around;
    }
  }

  // &:before {
  //   content: '';
  //   background-color: rgba($black, 0.3);
  //   width: 100%;
  //   height: 100%;
  // }
}

.my-home-dialogue {
  visibility: hidden;
  padding: 0px !important;
  height: 1px;
}

#{$myHomeDialogue} {
  visibility: visible;
  justify-content: center;

  .button {
    min-width: unset;
    width: auto;
    margin: 10px;

    &:before {
      content: unset;
    }
  }

  .radio {
    display: flex;
    align-items: center;
    padding: 10px;
  }

  input[type='radio'] {
    width: fit-content;
    min-width: 30px;
  }

  .my-home-dialogue-label {
    position: static;
    color: $white !important;
    font-size: 0.9rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
  }

  .error-form-message {
    color: $white;
    text-align: center;
  }
}

.quotation-sx .quotation-container .button.primary-button[disabled] {
  pointer-events: none;
}

// QUOTATION IMAGE
.quotation-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: calc(34% - 90px);
  height: 100vh;
  min-height: 100px;
  position: fixed;
  top: 0;
  left: 33%;
  border: 1rem solid $white;
  box-sizing: border-box;

  .footer-note-wrapper {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: 0%;
    z-index: 1;
    padding: 1rem;

    & > p,
    a,
    button {
      font-family: $primary-font;
      color: #fff;
      font-size: 0.75rem;
    }

    .footer-title {
      margin-top: 0;
    }

    .policiesContainer {
      height: 3rem;
      overflow: hidden;
      padding-inline-start: unset;

      &.open {
        height: auto;
        max-height: 900px;
        overflow: scroll;
      }

      .policies-wrapper {
        .note-text {
          font-weight: 400;
        }
      }
    }

    .footer-btn {
      font-size: 14px;
      padding: 1rem 0 0 0;
      border: 0;
      background-color: transparent;
    }
  }
}

// QUOTATION DX
.quotation-dx {
  background-color: $preventivo-dx-color-light;
  display: flex;
  flex-wrap: wrap;
  width: 33%;
  min-width: 600px;
  height: 100vh;
  min-height: 100px;
  position: fixed;
  top: 0;
  right: 88px;
  box-sizing: border-box;
  overflow-y: hidden;
  z-index: 3;
  padding: 1rem 2rem;
}

.quotation-dx .quotation-container {
  background-color: $preventivo-dx-color-dark;
  width: 100%;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  box-sizing: border-box;
}

.quotation-dx .quotation-container .header {
  font-family: $secondary-font;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  padding: 1rem 2rem 3rem 2rem;
}

.quotation-dx .quotation-container .header .quotation-name {
  font-family: $primary-font;
  background-color: $preventivo-dx-color-dark;
  bottom: -7px;
  top: inherit;
  @extend %center-absolute-X;
}

.quotation-dx .quotation-container .header h2,
.quotation-dx .quotation-container .header h2 i {
  color: $tertiary-color;
  font-size: 1.7rem;
  margin: 0;
}

.quotation-dx .quotation-container .header h4 {
  color: $tertiary-color;
  margin: 0;
}

.quotation-dx .quotation-container > div h6 {
  color: $tertiary-color;

  &:before {
    background-color: $primary-color;
  }
}

.quotation-container > div > div {
  border-bottom: 1px solid $preventivo-dx-color-dark;
}

// pacchetti promozionali
.quotation-dx .quotation-container .promozioni {
  background-color: lighten($success-color, 30%);
  margin: 0 -0.5rem 0.5rem;
  border: 0.5rem solid $primary-color;
}

.quotation-dx .quotation-container .promozioni > div {
  color: $success-color;
}

.quotation-dx .quotation-container .promozioni h6 {
  color: $primary-color;

  &:before {
    background-color: $primary-color;
  }
}

// ROTATED
.quotation-sx.rotated {
  width: 55%;
  height: 65%;
  top: 32%;
  transform: rotate(180deg);
  padding: 3rem 2rem 0 2rem;
}

.quotation-sx.rotated .quotation-container {
  justify-content: space-between;
}

.quotation-sx.rotated .quotation-container > div {
  flex: 0 0 100%;

  @media screen and (max-width: 1400px) {
    flex: 0 0 100%;
  }
}

.quotation-sx.rotated .side-button {
  left: -19px;
  transform: rotate(180deg);
}

.quotation-sx.rotated .quotation-container .pagamenti .name {
  flex-basis: 20%;
}

.quotation-sx.rotated .quotation-exit {
  left: -188px;
  z-index: 2;
  top: 38%;
  border-radius: 10px 0 0 10px;
}

.quotation-image {
  z-index: 3;
}

.quotation-image.rotated {
  width: calc(66% - 70px);
  left: 0;
}

input.invalid {
  border-color: red;
}

input.valid {
  border-color: unset;
}

.save-loading {
  width: 100%;
}

// keyboard
.quotation-sx .react-simple-keyboard {
  margin-top: 2rem;
}

.quotation-sx .MuiInputBase-root.MuiInput-root.MuiInput-underline {
  line-height: 1.6;
  color: $text-color;
}

.quotation-sx .MuiSelect-selectMenu {
  font-family: $primary-font;
  color: $text-color;
  height: 25px;
  padding: inherit;
  min-width: 85px;
}

.quotation-sx .MuiSelect-select:focus,
.quotation-sx .MuiSelect-select:hover,
.quotation-sx .MuiInput-underline:hover:not(.Mui-disabled):before {
  background-color: inherit;
  border-bottom: 1px solid $primary-color;
}

.quotation-sx .MuiInput-underline:after,
.quotation-sx .MuiInput-underline:hover:after {
  content: none;
}

.quotation-sx .MuiInputBase-root {
  font-family: $primary-font;
  letter-spacing: 0;
}

.MuiPickersDay-daySelected {
  background-color: $tertiary-color !important;
}

.quotation-sx .MuiFormControl-marginNormal {
  margin-top: 0;
  margin-bottom: 0;
  width: 70%;
}

.quotation-sx .MuiIconButton-root {
  padding: 5px;
}

.MuiInputAdornment-positionEnd {
  margin-left: 0;
}

.quotation-sx .floor-select .MuiSelect-selectMenu {
  min-width: 25px !important;
}

.quotation-sx .floor-select-title {
  align-items: center;
}

@media screen and (max-width: 1400px) {
  .quotation-sx .side-button.button-ruota {
    display: none;
  }

  .quotation-sx .side-button.button-reset {
    top: calc(10% + 60px);
  }

  .quotation-sx,
  .quotation-dx {
    width: 47% !important;
    min-width: inherit;
  }
}

@media screen and (max-width: 1200px) {
  .quotation-sx,
  .quotation-dx {
    width: 50%;
    min-width: inherit;
  }

  .quotation-dx {
    right: 0;
  }
}

.free-notes-wrapper {
  border-bottom: unset !important;
}

.free-notes {
  float: left;
  width: 100%;
  min-height: 75px;
  outline: none;
  resize: none;
  border: 1px solid $preventivo-color-light;
}

.listPolicies {
  margin-left: -40px;
}

.switch-thumb {
  color: $primary-color;
}

.switch-track {
  color: $primary-color;
}

// Fix grafica rimozione date picker saldo a rogito
.quotation-sx .quotation-container .pagamenti {
  .dotazione-row.saldo-rogito {
    // div price
    > div:nth-child(3) {
      flex: 1 0 45% !important;
    }

    // div prezzo ivato
    > div:nth-child(5) {
      flex: 1 0 45% !important;
    }

    // div percentage
    > div:nth-child(5) {
      flex: 1 0 45% !important;
    }

    // div date
    > div:nth-child(6) {
      width: 0 !important;
      flex-basis: 0% !important;
      display: none !important;
    }
  }
}

span.iva-same-value {
  margin-right: 25px;
  font-weight: 600 !important;
}

span.iva-same-value0 {
  font-weight: 600 !important;
}

.promos .dotazione-row {
  div:nth-child(3) {
    flex-basis: 44% !important;
  }

  div:nth-child(4) {
    flex-basis: 44% !important;
    margin-right: 1.4rem !important;
  }

  div:last-child {
    flex-basis: 100% !important;
  }
}

.upselling-button {
  margin: -0.5rem auto 1rem auto;

  span,
  p {
    margin-top: 0px !important;
  }

  span.upselling-button-span {
    font-size: 1.1rem;
    padding: 0rem 1.5rem 0.8rem 1.5rem;
    font-weight: bold !important;
  }
}

.prezzo.prezzo-totale {
  .capitolato-container .name {
    flex-basis: 60%;
  }
}

// Pulsante upselling preventivatore
.upselling-button.preventivatore {
  display: block;
  width: 100%;
  margin: -0.5rem auto 1rem auto !important;
  padding: 10px 10px !important;

  span {
    text-align: center;
    padding: 0;
  }

  p {
    line-height: 1;
  }
}

// Amenities in modale
.offcanvas-cart__button.amenities {
  width: 200px !important;
  right: -200px !important;
  border-radius: 0 90px 90px 0 !important;

  &:after {
    content: '' !important;
  }

  color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.offcanvas-cart.amenities {
  z-index: 1000 !important;
  transform: translateX(-500px) !important;

  &.open {
    transform: translateX(0px) !important;
  }

  .offcanvas-cart__wrapper-amenities {
    width: 500px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
    background-color: white;

    .dotazioni {
      background-color: white;
      margin: 0 0 0.5rem;
      padding: 1rem 2rem;
      font-size: 0.9rem;
      font-weight: 700;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      box-sizing: border-box;
      position: relative;
      padding-top: 3rem;

      h6 {
        position: relative;
        font-size: 0.85rem;
        margin-top: 0;
        margin-bottom: 12px;
        line-height: 1.25;
        font-weight: 700;
      }

      .add-line-container {
        font-size: 0.9rem;
        font-weight: 700;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        margin: 0.1rem 0;
        padding: 0.2rem 0;
        border-bottom: 1px solid #955550;
        box-sizing: border-box;
        position: relative;

        .add-line {
          background: #955550;
          color: #fff;
          text-align: center;
          line-height: 1.5;
          height: 18px;
          width: 18px;
          position: absolute !important;
          top: -10px;
          left: -27px;
          border: 1px solid #fff;
          border-radius: 50%;
          box-shadow: 0 0 0 1px #955550;
          transition: transform 0.3s, box-shadow 0.3s;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .dotazione-row {
        font-size: 0.9rem;
        font-weight: 700;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        margin: 0.1rem 0;
        padding: 0.2rem 0;
        box-sizing: border-box;
        position: relative;

        .remove-line {
          background: #955550;
          color: #fff;
          text-align: center;
          line-height: 1.5;
          height: 18px;
          width: 18px;
          position: absolute !important;
          border: 1px solid #fff;
          border-radius: 50%;
          box-shadow: 0 0 0 1px #955550;
          transition: transform 0.3s, box-shadow 0.3s;
          cursor: pointer;
          left: -30px !important;
          top: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        label {
          font-weight: 400;
          position: absolute;
          bottom: -17px;
          left: 0;
        }

        .MuiSelect-selectMenu {
          color: #333;
          height: 25px;
          padding: inherit;
          min-width: 107px;
        }

        input {
          background-color: transparent;
          width: 90%;
          height: 25px;
          border: 0;
          border-bottom: 1px solid #955550;
          margin: 0 1rem 0 0;
          min-width: 80px;
          padding: 0;
        }
      }
    }

    .dotazione-row {
      display: flex;
      padding: 0 0 1.2rem 0;
      border-bottom: 0;
    }

    .dotazione-row > div {
      flex: 1 0 auto;
      position: relative;
      margin-top: 1.2rem;
    }

    .dotazione-row > div:last-child input {
      margin: 0;
    }

    .dotazione-row div.amn_type_selector_container {
      //flex-basis: 15%;
      margin-right: 5px;
    }

    .dotazione-row div:nth-child(3) {
      //flex-basis: 15%;
      margin-right: 1rem;
    }

    .dotazione-row {
      padding-left: 15px !important;

      .remove-line {
        line-height: 1.2;
        left: -20px !important;
        background-color: #e07e63 !important;
        border: 1px solid white !important;
        box-shadow: 0 0 0 1px #e07e63 !important;
      }

      .MuiSwitch-root {
        position: absolute !important;
        top: -10px;
        left: -40px;

        .MuiButtonBase-root {
          padding: 9px !important;
        }
      }
    }

    // DIALOG
    .dialogue {
      background-color: $preventivo-color-dark;
      width: 350px;
      left: 50%;
      top: 50%;
      transform: translate(0%, 0%);
      padding: 2rem !important;
      display: flex;
      flex-wrap: wrap;
      border-radius: 0;
      z-index: 9;

      &:before {
        content: '';
        background-color: rgba($black, 0.3);
        width: 180vw;
        height: 500vh;
        position: fixed;
        top: -1000%;
        z-index: -1;
      }
    }

    .dialogue h4 {
      color: $white;
      font-size: 0.9rem;
      text-align: center;
      text-transform: uppercase;
      font-weight: 300;
    }

    .dialogue .button {
      background-color: $preventivo-dx-color-dark;
      color: white !important;
      width: 65px;
      margin: 0.5rem;
      border-radius: 0;
    }
  }
}

.switch-payment-container {
  span.MuiSwitch-root {
    left: -10px !important;
    top: 0 !important;
  }

  flex: 1 0 15%;
}

.pagamenti {
  .dotazione-row.with-switch {
    .switch-payment-container {
      flex: 1 0 5% !important;
      margin: 0;
    }

    > input:first-of-type {
      width: 80% !important;
    }
  }
}

.lock-icon {
  width: 24px;
  height: 24px;
  background-size: 18px 18px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-color: #955550;
  transform: translateY(-2px);
}

.expiry-date-wrapper {
  padding: 0 !important;
  margin-bottom: 0 !important;
}

/* Selezione IVA */
.quotation-sx .select-editable.vat-select,
.modal-container .dotazioni .vat-select {
  position: relative;
  height: 25px;
  display: flex;
  align-items: center;

  &.disabled {
    .vat-select-arrow {
      visibility: hidden;
    }
  }

  input,
  .vat-select-arrow {
    display: flex;
  }

  input {
    flex-grow: 1;
    margin-right: 0px;
    width: 30px;
  }

  .vat-select-arrow {
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-position: center center;
    cursor: pointer;
  }

  .vat-select-options {
    position: absolute;
    width: 180px;
    top: 100%;
    right: 0;
    background: white;
    border: 1px solid black;
    border-radius: 2px;
    padding: 5px;
    z-index: 10000;

    &.closed {
      visibility: hidden;
    }

    .vat-select-single-option {
      cursor: pointer;
      padding: 2px;

      &:not(:last-child) {
        border-bottom: 1px solid black;
      }
    }
  }
}

// Adattamento grafico per IVA
.values-section,
.modal-container .dotazioni {
  .switch-show-iva-container {
    display: none !important;
    position: relative !important;
    border: none !important;
    align-items: center;

    > span.name {
      text-transform: initial;
    }

    .MuiSwitch-switchBase {
      padding: 9px !important;
    }

    &.shown {
      display: flex !important;
    }

    .max-iva-selector {
      width: 180px;
      max-width: 180px;
    }

    .iva-switch-inner {
      right: -10px;
      position: absolute;

      height: 100%;
      display: flex;
      align-items: flex-end;
    }

    .iva-container-text {
      font-family: 'Segoe UI', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      margin: 0 0 10px;
    }
  }

  .vat-select-container.element-iva {
    input {
      width: 150px;
    }
  }

  .vat-visualization {
    border: none !important;
    cursor: pointer;

    .view-icon-iva {
      width: 15px;
      height: 15px;
      background-size: 12px;

      background-color: black;

      -webkit-mask-repeat: no-repeat;
      -webkit-mask-position: center;
      transform: rotate(-90deg);

      &.open {
        transform: rotate(90deg);
      }
    }

    p {
      margin: 0;
    }
  }
}

.quotation-sx,
.quotation-dx,
.offcanvas-cart__wrapper-amenities {
  &.hide-iva {
    .element-iva {
      display: none !important;
    }
  }
}

// Grafica parte sinistra
.quotation-sx,
.modal-container .dotazioni {
  .element-iva {
    span.name {
      text-transform: none;
      text-align: left;
      padding-left: 10px;
    }

    span {
      font-size: 11px;
      font-weight: 400;
    }
  }
}

// Grafica parte destra
.quotation-dx {
  .element-iva {
    span.name {
      text-transform: none;
      text-align: left;
      padding-left: 10px;
    }

    span {
      font-size: 11px;
    }

    &.expense-empty {
      .name {
        flex: 0 0 38%;
      }

      .data {
        font-weight: 400;
      }
    }
  }
}

/* Fine selezione IVA */

/* Modifica grafica spaziatura riga saldo a rogito da altri pagamenti */
.quotation-sx .quotation-container .pagamenti {
  .dotazione-row.saldo-rogito {
    margin-top: 30px;
  }
}
