@import './mixin.scss';

.cms-snackbar {
  position: fixed;
  top: 20px;
  right: 40px;
  z-index: 10000000;
  width: auto;
  min-width: 500px;
  height: auto;
  min-height: 50px;
  background: white;
  border-radius: 1px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  transition: opacity 0.3s linear;

  &.not-showing {
    opacity: 0;
    z-index: -5;
  }

  .text-container {
    @include text($color: #333333, $fontSize: 14px, $fontWeight: 400);
    flex-grow: 1;
  }

  span.icon-container {
    border-radius: 50%;
    filter: none;
    width: 18px;
    height: 18px;
    background-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    div.icon {
      display: inline-block;
      width: 6px;
      height: 6px;
      &.check-white {
        background-size: 7px;
      }
    }
  }

  .action-button {
    margin-left: 40px;
    cursor: pointer;
    color: #6bc1b4;
  }
}
