.simple-modal {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100000000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-card {
    position: relative;
    background-color: #fbfbfb;
    width: 600px;
    background: #fbfbfb;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 32px;
    font-family: 'Segoe UI';
  }
  &-close-icon {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    & > span.icon {
      display: block;
      background-size: 15px;
    }
  }
  &-title {
    text-align: center;
    font-weight: 300;
    font-size: 42px;
    margin-bottom: 16px;
  }
  &-subtitle {
    text-align: center;
    font-weight: 100;
    font-size: 18px;
    margin-bottom: 32px;
  }
  &-body {
    margin-bottom: 32px;
  }
  &-actions {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
}
