.interior-experience-menu {
  .interior-experience-planimetry-container {
    display: flex;
    user-select: none;

    .left-column,
    .right-column {
      display: inline-flex;
      width: 50%;
      flex-direction: column;
    }

    .pins-container {
      margin-top: 10px;
      z-index: 1;
      position: relative;

      .gblcbk {
        border-radius: 50%;
        border-color: transparent;

        .rotate {
          background-image: none !important;
        }
      }

      .single-cono {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 52px;
        height: 52px;

        &.error {
          > .interior-experience-pin {
            background-color: #e78989;
            border-color: red;
          }
        }

        .interior-experience-pin {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 52px;
          height: 52px;
          background-color: #d2d2d2;
          border-radius: 50%;
          border: 2px solid transparent;
        }

        span.cono-interior-experience {
          display: inline-flex;
          width: 50px;
          height: 50px;
          background-size: 45px;
          position: relative;

          .cono-index {
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 12px;
            color: rgba(0, 0, 0, 0.87);
            &.active {
              color: #fff;
            }
          }
        }
      }
    }

    .planimetry-container {
      position: relative;

      .planimetry-image {
        height: 600px;
        width: 100%;
        background-position: 50% center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
  .cms-pins-switcher-container {
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 20px auto;

    & .pins-container {
      display: flex;
      align-items: center;
      gap: 20px;

      .single-cono {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 52px;
        height: 52px;

        .interior-experience-pin {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 52px;
          height: 52px;
          background-color: #d2d2d2;
          border-radius: 50%;
          border: 2px solid transparent;
        }

        span.cono-interior-experience {
          display: inline-flex;
          width: 50px;
          height: 50px;
          background-size: 45px;
          position: relative;

          .cono-index {
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 12px;
            color: rgba(0, 0, 0, 0.87);
            &.active {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
