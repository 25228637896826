.keyboard-container {
    display: flex;
    justify-content: center;
    //position: absolute;
    left: 0;
    bottom: 2vh;
    width: 100%;
}

.keyboard-container > .react-simple-keyboard {
    max-width: 70%;
    font-size: 1.5vh;
    z-index: 2;
}

.hg-button {
    height: 4.5vh;
    border-radius: 0.4vh;
    color: #000;
    &:not(:last-child) {
        margin-right: 0.5vh !important;
    }
}

.hg-functionBtn {
    justify-content: center;
    align-items: center;
}

.hg-button-bksp, .hg-button-enter, .hg-button-shift:last-child {
    justify-content: center
}

.hg-button-space {
    flex-grow: 10 !important;
}

.modal.modal__zoom-plan.full-page {
    .react-simple-keyboard {
        min-width: 600px!important;
    }
} 
  
  /*
    Theme: darktheme
  */
.simple-keyboard.darktheme {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    .hg-button {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        &:active {
            background: #1c4995;
            color: white;
        }
    }
}
.simple-keyboard.darktheme + .simple-keyboard-preview {
    background: #1c4995;
}