@use "../../../../theme/utils/variables" as *;

.date-step-picker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  &-label {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-size: $defaultFont;
    color: $background-1-elements;
    text-transform: capitalize;
  }
}

.date-value-container {
  display: flex;
  .day-month-container,
  .year-container {
    display: flex;
    align-items: center;
    border: 0.0625rem solid $neutral-90;
    border-radius: 0.125rem;
  }
  .day-month-container {
    + .year-container {
      margin-left: 0.5rem;
    }
  }
}
