@import "../../theme/utils/variables.scss";

.tecma-checkbox {
  appearance: none;
  background-color: #fff;
  margin: 0;
  position: relative;
  width: 1rem;
  height: 1rem;
  border: 0.15rem solid $primary;
  border-radius: 0.15rem;
  cursor: pointer;

  &::before  {
    position: absolute;
    content: "";
    box-shadow: inset 1rem 1rem $primary;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    transform-origin: top left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  &:checked::before {
    transform: scale(1);
  }

  &.secondary {
    border-color: $secondary;
    &::before {
      box-shadow: inset 1rem 1rem $secondary;
    }
  }
  
  &.small {
    width: 1rem;
    height: 1rem;
    &::before {
      width: 0.70rem;
      height: 0.70rem;
    }
  }
  
  &.medium {
    width: 1.5rem;
    height: 1.5rem;
    &::before {
      width: 1rem;
      height: 1rem;
      top: 5%;
      left: 5%;
    }
  }
  
  &.large {
    width: 2rem;
    height: 2rem;
    &::before {
      width: 1.5rem;
      height: 1.5rem;
      top: 5%;
      left: 5%;
    }
  }

  &.disabled {
    border-color: $semantic-disabled;
    &::before {
      box-shadow: inset 1rem 1rem $semantic-disabled;
    }
  }
}
