@import './../common/modals.scss';
@import './../common/icons.scss';
@import './../common/fonts.scss';

@import './pages/genericPage.scss';
@import './pages/interiorExperience';
@import './pages/selectTiles';
@import './pages/servizi';
@import './pages/sliderGallery';
@import './pages/componentSwitcher';
@import './pages/virtualPhoto';
@import './pages/video';
@import './pages/settings';

.cms-container,
.cms-top-bar,
.cms-snackbar {
  font-family: 'SegoeRegular', sans-serif;
}

.cms-sidebar-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .cms-full-height {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
