/**custom alternative**/

@mixin divDimensions ($width, $height, $max-width: auto, $max-height: auto, $min-width: auto, $min-height: auto) {
	height: $height;
	width: $width;
	max-height: $max-height;
	max-width: $max-width;
	min-height: $min-height;
	min-width: $min-width;
}

/**display properties**/
//flex
@mixin flexContainer ($direction: null, $align-items: null, $align-content: null, $justify-content: null, $flex-wrap: null) {
	display: -webkit-flex;
	display: flex;
	flex-direction: $direction;
	-webkit-flex-direction: $direction;
	align-items: $align-items;
	align-content: $align-content;
	justify-content: $justify-content;
	flex-wrap: $flex-wrap;
}

//flex-children
@mixin flexChildren ($flex-grow: null, $flex-shrink: null, $flex-basis: null, $align-self:null) {
	flex-grow: $flex-grow;
	flex-shrink: $flex-shrink;
	flex-basis: $flex-basis;
	align-self: $align-self;
}

//SecondWindows display properties
@mixin display ($display) {
	@if $display==none {
		display: $display;
	} @else if $display==block {
		display: $display;
	} @else if $display==inline {
		display: $display;
	} @else if $display==inline-block {
		display: $display;
	}
}

/**background properties**/
@mixin backgroundColor ($background-color: null, $opacity:null) {
	background-color: $background-color;
	opacity: $opacity;
}

/**positions properties**/
@mixin position($position: null, $top: null, $right: null, $bottom: null, $left: null) {
	position: $position;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

/**padding properties**/
@mixin padding($top: null, $right: null, $bottom: null, $left: null) {
	padding-top: $top;
	padding-right: $right;
	padding-bottom: $bottom;
	padding-left: $left;
}

/**margin properties**/
@mixin margin($top: null, $right: null, $bottom: null, $left: null) {
	margin-top: $top;
	margin-right: $right;
	margin-bottom: $bottom;
	margin-left: $left;
}

/**border properties**/
//border-radius
@mixin border($border-radius: null, $border-style: null, $border-width: null, $border-color: null, $border-left-width: null, $border-top-width: null, $border-right-width: null, $border-bottom-width: null) {
	border-radius: $border-radius;
	border-style: $border-style;
	border-width: $border-width;
	border-left-width: $border-left-width;
	border-top-width: $border-top-width;
	border-right-width: $border-right-width;
	border-bottom-width: $border-bottom-width;
	border-color: $border-color;
	-webkit-border-radius: $border-radius;
	-moz-border-radius: $border-radius;
	-ms-border-radius: $border-radius;
}

/**text display properties**/
@mixin text($text-color: null, $letter-spacing: null, $line-height: null, $text-align: null, $text-decoration: null, $text-shadow: null, $text-transform: null) {
	letter-spacing: $letter-spacing;
	color: $text-color;
	line-height: $line-height;
	text-align: $text-align;
	text-decoration: $text-decoration;
	text-shadow: $text-shadow;
	text-transform: $text-transform;
}

.slider,
.slider:before,
.slider:after {
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 100vh;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 90px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    //background-color: $secondary-color;

}

.slider-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
}

.slide {
    display: inline-block;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.SliderButton {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $secondary-color;
    background-position: 45%;
    background-repeat: no-repeat;
    background-size: 50%;
    border-radius: 50%;
    cursor: pointer;
    transition: transform ease-in .1s;
    top: 50%;
    position: absolute;
    z-index: 10;


}

.nextArrow {
    right: 20px;
    transform: rotate(180deg);
}

.prevArrow {
    left: 10px;
}


.Indice--button-toggle {
    @include divDimensions (150px, 50px, $max-width: auto, $max-height: auto, $min-width: auto, $min-height: auto);
    @include backgroundColor($background-color: $secondary-color, $opacity:null);
    @include flexContainer($direction: row, $align-items: center, $align-content: center, $justify-content: center, $flex-wrap: null);
    cursor: pointer;
    color: $white;
    //@include font-face($font-family:$thirdFont, $font-weight: 900, $font-style: null, $font-size: 14px);
    border-radius: 50px;
    text-transform: uppercase;

    &.top {
        @include position($position: fixed, $top: 20px, $right: 100px, $bottom: null, $left: null);
        z-index: 100;
    }

    &.left {
        @include position($position: fixed, $top: 20px, $right: null, $bottom: null, $left: 50px);
        z-index: 10;

        &.on {
            display: none;
        }
    }

    &.fullImage {
        @include position($position: fixed, $top: 20px, $right: 100px, $bottom: null, $left: null);
        z-index: 10;

        &.on {
            display: none;
        }
    }

}

.indice--button {
    @extend .Indice--button-toggle;
    @include divDimensions (auto, 50px, $max-width: auto, $max-height: auto, $min-width: 150px, $min-height: auto);
    @include position($position: relative, $top: 0px, $right: auto, $bottom: auto, $left: null);
    @include backgroundColor($background-color:$secondary-color, $opacity:null);
    @include margin($top: 10px, $right: 10px, $bottom: 10px, $left: 10px);
    padding: 10px;
    text-transform: uppercase;
    border-radius: 50px;

    &.close {
        @include divDimensions (auto, 50px, $max-width: auto, $max-height: auto, $min-width: 50px, $min-height: auto);
        @include margin($top: 10px, $right: 10px, $bottom: 40px, $left: 10px);
        border: 2px solid $secondary-color;
        background-color: transparent;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 50%;
    }
}

.indice--image--wrapper {
    @include divDimensions (800px, auto, $max-width: auto, $max-height: auto, $min-width: auto, $min-height: auto);
    @include flexContainer($direction: row, $align-items: center, $align-content: center, $justify-content: center, $flex-wrap: wrap);

    & > .indice--image {
        @include divDimensions (150px, 50px, $max-width: auto, $max-height: auto, $min-width: auto, $min-height: auto);
        @include position($position: relative, $top: 0px, $right: auto, $bottom: auto, $left: null);
        @include margin($top: 20px, $right: 20px, $bottom: 20px, $left: 20px);
        background-color: transparent;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
    }

}

.modal .modal__wrapper .slider, .modal .modal__wrapper .slider:after, .modal .modal__wrapper .slider:before {
    background-color: transparent !important;
}

.Indice--menu {
    z-index: 11;
    @include backgroundColor($background-color:$secondary-color, $opacity: 0.9);
    transition: all .6s ease-out;

    &.top {
        @include divDimensions (100%, 200px, $max-width: null, $max-height: null, $min-width: null, $min-height: null);
        @include position($position: fixed, $top: 0px, $right: null, $bottom: null, $left: 0px);
        @include flexContainer($direction: row, $align-items: center, $align-content: center, $justify-content: center, $flex-wrap: null);

        &.off {
            @include position($position: fixed, $top: -250px, $right: null, $bottom: null, $left: null);
        }
    }

    &.left {
        @include divDimensions (500px, 100%, $max-width: null, $max-height: null, $min-width: null, $min-height: null);
        @include position($position: fixed, $top: 0px, $right: null, $bottom: 0px, $left: 0px);
        @include flexContainer($direction: column, $align-items: center, $align-content: center, $justify-content: center, $flex-wrap: null);

        &.off {
            @include position($position: fixed, $top: null, $right: null, $bottom: null, $left: -500px);
        }
    }

    &.fullImage {
        @include divDimensions (100%, 100%, $max-width: null, $max-height: null, $min-width: null, $min-height: null);
        @include position($position: fixed, $top: 0px, $right: 0px, $bottom: 0px, $left: 0px);
        @include flexContainer($direction: row, $align-items: center, $align-content: center, $justify-content: center, $flex-wrap: null);
        @include backgroundColor($background-color:$secondary-color, $opacity: 1);

        &.off {
            @include position($position: fixed, $top: -100%, $right: null, $bottom: null, $left: null);
        }
    }


    
}