@import './../../common/mixin.scss';

.component-switcher-menu {
  .template-container {
    .cms-all-radios.single-row {
      display: flex;

      .single-checkmark-container {
        display: inline-flex;
        flex-grow: 1;
        justify-content: flex-start;
      }
    }

    .template-descriptions {
      display: flex;
      margin-top: 4px;
      font-style: italic;
      .single-template-desc-container {
        display: inline-flex;
        flex-grow: 1;
        @include text($fontSize: 12px, $color: #000000, $fontWeight: 400);
      }
    }

    .template-preview {
      display: flex;
      margin-top: 8px;

      .single-template-prev-container {
        display: inline-flex;
        flex-grow: 1;
      }
    }
  }
}
