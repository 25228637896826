@import './../../common/mixin.scss';

.slider-gallery-menu {
  .draggable-list {
    .draggable-list-elements {
      .draggable-element,
      .draggable-element.draggable-element-placeholder {
        margin: 0px !important;
        border: none;
        background-color: white;
        padding-left: 0px !important;
        cursor: default;
        -webkit-user-select: unset;
        user-select: unset;
        align-items: stretch;

        .draggable-icon {
          display: flex;
          align-items: center;
        }

        &:nth-child(2n) {
          background-color: #f4f4f5;
        }

        .element-content {
          margin-left: 0px !important;

          .slider-gallery-drag-content {
            display: flex;
            align-items: stretch;
            flex-grow: 1;
            height: 100%;

            .trash-container {
              display: flex;
              align-items: center;
            }

            .drag-index {
              width: 48px;
              min-height: 100%;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              background-color: white;
            }

            .checkbox-capitolo-container {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              margin-left: 24px;
            }

            .slider-gallery-info-container {
              display: flex;
              flex-direction: column;
              justify-content: center;
              flex-grow: 1;
              margin-left: 30px;
              padding: 10px 0px;

              input {
                border-radius: 0px;
                border: 1px solid #909090;
                margin-top: 5px;
              }

              .slider-gallery-capitolo-container {
                margin-bottom: 10px;

                .cms-file-input-container {
                  margin-top: 10px;
                }

                .button-cms {
                  text-align: center;
                }
              }

              .slider-file-info {
                display: flex;
                align-items: center;
                .cms-file-extension-container {
                  margin-right: 16px;
                }
              }
            }

            span.trash {
              margin-right: 30px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
