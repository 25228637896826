@use "../../theme/utils/variables" as *;

.tecma-card {
  padding: 2rem;
  border-radius: 0.5rem;
  width: 30rem;
  background-color: white;
  box-shadow: 0 0 1.2rem -1rem;
  display: flex;

  &.fullSize {
    .tecma-card-footer,
    .tecma-card-header,
    .tecma-card-content,
    .tecma-card-media {
      margin: 0 -2rem;
    }

    .tecma-card-media:first-child {
      * {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        margin-top: -2rem;
      }
    }
  }

  &.horizontal {
    padding: 0;

    .tecma-card-media {
      min-width: 40%;
      height: unset;
      flex-grow: 1;
      margin: 0;
    }

    .tecma-card-header,
    .tecma-card-content,
    .tecma-card-footer {
      margin: 0;
    }
  }

  &.horizontal,
  &.fullSize {
    .tecma-card-media:first-child {
      * {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0.5rem;
      }
    }
  }

  &.vertical {
    flex-direction: column;
  }

  &.horizontal {
    flex-direction: row;
  }

  &.fluid {
    width: 100%;
  }

  .tecma-card-media {
    height: 20rem;
    overflow: hidden;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .tecma-card-media:first-child {
    &.fullSize {
      * {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        margin-top: -2rem;
      }
    }
  }

  .tecma-card-footer,
  .tecma-card-header,
  .tecma-card-content,
  .tecma-card-media {
    &.fullSize {
      margin: 0 -2rem;
    }
  }

  &.selected {
    box-shadow: none;
    outline: 0.188rem solid rgba($primary-dark, 0.3);
  }
}
