// Token globali - Colori principali
$primary: #40495e;
$primary-light: #5e6d8c;
$primary-dark: #212631;
$primary-elements: #f0f1f5;

$secondary: #ccccd1;
$secondary-light: #f4f4f5;
$secondary-dark: #a4a4ac;
$secondary-elements: #0c0c0d;

$accent: #ad78b0;
$accent-light: #caa8cc;
$accent-dark: #87518a;
$accent-elements: #ffffff;
$accent-alt-elements: #ffffff;

$accent-alternative: #0db392;
$accent-alternative-light: #20efc6;
$accent-alternative-dark: #086d59;

//Token alias - Colori specifici
$background-color-1: #dbe1e1;
$background-color-2: #f4f4f5;
$background-color-3: #212631;

$background-1-elements: #1a1a1a;
$background-2-elements: #1a1a1a;
$background-3-elements: #f2f2f2;

$background-alternative: #ffffff;
$background-alt-elements: #ad78b0;

//Token globali - Colori fissi
$neutral-000: #000000;
$neutral-10: #1a1a1a;
$neutral-20: #333333;
$neutral-30: #4d4d4d;
$neutral-40: #666666;
$neutral-50: #808080;
$neutral-60: #999999;
$neutral-70: #b3b3b3;
$neutral-75: #bfbfbf;
$neutral-80: #cccccc;
$neutral-85: #d9d9d9;
$neutral-90: #e6e6e6;
$neutral-95: #f2f2f2;
$neutral-100: #ffffff;

//Palette cromatica per elementi e messaggi di avviso
$semantic-warning: #ffa724;
$semantic-warning-dark: #bd7100;
$semantic-warning-light: #ffd08a;

//Palette cromatica per elementi e messaggi di errore
$semantic-alert: #f63131;
$semantic-alert-dark: #ad0404;
$semantic-alert-light: #ffa8a8;

//Palette cromatica per elementi e messaggi di successo
$semantic-success: #12be9c;
$semantic-success-dark: #096250;
$semantic-success-light: #49eecd;

//Palette cromatica per elementi e messaggi di info
$semantic-info: #296bab;
$semantic-info-dark: #1b456f;
$semantic-info-light: #4e92d5;

//Palette cromatica per elementi disabilitati
$semantic-disabled: #bfbfbf;
$semantic-disabled-dark: #8c8c8c;
$semantic-disabled-light: #f2f2f2;

// font size 1rem === 16px
$extraSmallFont: 0.625rem; //10px
$smallFont: 0.76rem; //12px
$defaultFont: 0.875rem; // 14px
$mediumFont: 1rem; //16px
$largeFont: 1.25rem; //20px
$extraLargeFont: 1.5rem; //24px


$primaryFont: "Segoe UI";
