@import './mixin.scss';

.button-cms {
  background-color: #6bc1b4;
  border-radius: 1px;
  padding: 12px 24px;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;

  &.disabled {
    background-color: rgba(107, 193, 180, 0.8);
    cursor: default;
    opacity: 0.6;
  }

  &.button-inverted {
    color: #909090;
    background-color: #fff;
    border: 1px solid #d3d3d3;

    &.disabled {
      color: #d0d0d0;
      border-color: #d0d0d0;
    }
  }
}

.cms-file-input-container .button-cms.flex-button-cms {
  display: flex;
}

// button group
.cms-button-group {
  display: flex;

  span {
    display: inline-flex;
    flex-grow: 1;
    border: 1px solid #6bc1b4;
    cursor: pointer;
    height: 35px;
    align-items: center;
    justify-content: center;

    @include text($color: #6bc1b4, $fontWeight: 700, $fontSize: 12px);

    &.active {
      background-color: #6bc1b4;
      color: white;
    }

    &:not(:first-child) {
      border-left: none;
    }
  }
}
