/* -------------------------
----------- COLORS ---------
--------------------------*/
$primary-color: #955550;
$secondary-color: #7e8253;
$tertiary-color: #e5ccb3;
$success-color: #8f9faa;
$alert-color: #fcd7b8;
$white: #fff;
$black: #000;
$gray: #8f9faa;

// TEXT COLOR
$text-color: #333;

// FLOOR COLOR

$floor-color: #d1d569;
$floor-color-na: #404a2f;
$floor-color-sc: #278b96;

// BACKGROUND COLOR
$background-color: #fff;
$background-color--2: #fff;

// LINK COLOR
$link-color: $primary-color;

// BUTTONS COLOR
$primary-button-color: $primary-color;

/* -------------------------
----------- FONTS ---------
--------------------------*/
@import url('https://use.typekit.net/fsc2bjj.css');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
$primary-font: p22-underground, sans-serif;
$secondary-font: 'PT Serif', serif;

// LOGO LOGIN
.primary-logo {
  width: 200px;
  height: 120px;
  margin: 1rem auto;
  display: block;
  filter: brightness(0) invert(1);
}

// LOGO HEADER
header {
  .primary__logo--header {
    background-repeat: no-repeat;
    width: 270px;
    height: 70px;
    margin: 0.7rem auto 1.3rem;
    display: block;

    @media screen and (max-width: 992px) {
      margin-top: 30px !important;
    }
  }
}

// LOGO SIDEBAR
.primary__logo--sidebar {
  width: 70px;
  height: 70px;
  margin: 0 auto;
  display: block;
  //filter: brightness(7.5);
}

// LOGO PLAN DESCRIPTION (pagina SOLUZIONI)
.plan-info__description--top {
  .plan-info__logo {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    display: block;
    position: absolute;
    top: 22px;
    opacity: 0.4;
    left: -150px;
    filter: brightness(10);
  }
}

// LOGO HEADER VISTA (pagina PIANO)
.views-wrapper__header {
  .views-wrapper__logo {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    display: block;
  }
}

// LOGO RIEPILOGO
.section-costs--primary-logo {
  span {
    width: 260px;
    height: 40px;
    margin: 0 auto;
    display: block;
  }
}

// PRE-FOOTER Icon separator
.post-main__separator {
  width: 30px;
  height: 30px;
  margin: 0 auto;
  display: block;
  background-repeat: no-repeat;
  background-position: 50%;
}

// PRIMARY BUTTON
button {
  &:focus {
    outline: none;
  }
}

.primary-button {
  background-color: $primary-button-color;
  color: $white;
  padding: 1rem;
  font-size: 0.85rem;
  //border-radius: 40px;
  transition: background ease-in 0.3s, color ease-in 0.3s, box-shadow ease-in 0.3s;
  position: relative;
  overflow: hidden;

  &:hover {
    background-color: $white;
    color: $primary-color;
    box-shadow: 0 0 0 2px $primary-color;
    /* &:after{
        transform: translateY(0%);
      } */
  }
}

// SECONDARY BUTTON
.secondary-button {
  color: $black;
  padding: 1rem 1.5rem;
  font-size: 0.9rem;

  &:hover {
    &:before {
      transform: scale(1.2);
    }
  }
}

// SUCCESS BUTTON (Primary variant)
.success--button {
  background: $secondary-color;

  &:hover {
    background: $primary-color;
  }
}

// BUTTONS PLAN DESCRIPTION (pagina SOLUZIONI)
.plan-image__button {
  @include icon(60%, no-repeat, center, 45px, 45px);
  box-shadow: 3px 4px 3px 0 rgba(0, 0, 0, 0.2);
  border: 10px solid #f9f3ee;
  display: block;
  border-radius: 50%;

  &.plan-image__button--zoom {
    //------------------------------------------------------------------------> Icona ZOOM
    background-color: $secondary-color !important;

    &.print {
      margin-left: 30px !important;
    }
  }

  &.plan-image__button--like {
    background-color: $secondary-color !important;

    &.active {
      background-color: $secondary-color !important;
      border-color: lighten($secondary-color, 25%);
    }
  }
}

// OFFCANVAS CART BUTTON
.button.button--cart {
  background: $white;
  color: $primary-color;

  &.active {
    color: $secondary-color;
    border: 3px solid $secondary-color;
  }
}

/* -------------------------
----------- ICONS  ---------
--------------------------*/
// MIXIN structure => $background-name, $background-size, $background-repeat, $background-position, $width, $height

/* Icone Sidebar */
.round-button {
  border-radius: 50% !important;
  border: 2px solid $white;
  cursor: pointer;

  &.round-button--back {
    //-----------------------------------------------------------------------------> BACK
    @include icon(32%, no-repeat, center, 45px, 45px);
    margin-top: 16px;
  }

  &.round-button--home-tap {
    //-------------------------------------------------------------------------> NO TAP
    @include icon(120%, no-repeat, center, 45px, 45px);
    position: relative;
    z-index: 9999999;
  }

  &.round-button--logout.client {
    //--------------------------------------------------------------------> LOGOUT Client
    //@include icon(70%, no-repeat, center, 45px, 45px);
    border: 2px solid white !important;
  }

  &.round-button--logout.vendor {
    //--------------------------------------------------------------------> LOGOUT Vendor
    //@include icon(70%, no-repeat, center, 45px, 45px);
    border: 2px solid white !important;
  }

  &.round-button--rotate {
    //----------------------------------------------------------------------------> SCREEN ROTATE
    @include icon(70%, no-repeat, center, 50px, 50px);
    background-color: $primary-color;
    border: 2px solid $white;
  }

  &.myHome {
    //--------------------------------------------------------------------> LOGOUT Vendor
    @include icon(auto, no-repeat, center, 45px, 45px);
    border: 2px solid white !important;
  }
    

  &.round-button--refresh {
    @include icon(auto, no-repeat, center, 45px, 45px);
    border: 2px solid $white;
  }

  &.round-button--empty {
    //@include icon(60%, no-repeat, center , 45px, 45px);
    color: #fff;
    border: 2px solid $white;
    //align-content: center;
    text-align: center;
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);

    &:after {
      font-size: 1.2rem;
      position: relative;
      width: 25px;
      top: 7px;
      line-height: 1.4;
    }

    &.language-switcher {
      margin-bottom: 16px;
    }
  }
}

/* Icona View - pagina PIANO */
.view-icon {
  @include icon(70%, no-repeat, center, 35px, 25px);
  background-color: $tertiary-color;
  margin: 0 0.2rem;
  border-radius: 5px;
}

/* Icone Cart - pagina RIEPILOGO */
.button.button--cart {
  &.active {
    &:before {
      @include icon(22px, no-repeat, 48% 15px, 100%, 100%); //----------------------------> Tasto active / icona rimuovi scelta
      filter: brightness(0);
    }
  }

  &:before {
    @include icon(30px, no-repeat, 48% 11px, 100%, 100%); //------------------------------> Tasto cliccabile / Icona metti nel carrello
    filter: brightness(0);
  }
}

/* -------------------------
--------- OVERRIDES --------
--------------------------*/
// LOGIN
.home-configurator.login {
  background-position: top center !important;
  background-color: rgba(0, 0, 0, 0.55) !important;
}

.login__wrapper {
  h1 {
    font-size: 2rem;
    margin: 1rem auto 3rem auto;
  }

  .login-form__wrapper {
    background: rgba($tertiary-color, 0.9) !important;
    box-shadow: 0 0 30px 10px rgba(128, 95, 95, 0.4);
  }

  label {
    color: $primary-color !important;
  }

  input {
    &.w-input {
      font-family: $primary-font;
      font-size: 0.9rem !important;
      border-bottom: 1px solid $primary-color !important;
      background: $white;
      color: $primary-color !important;

      &::placeholder {
        color: rgba($primary-color, 0.5);
      }
    }

    &.w-button {
      padding: 0.5rem 0 !important;
      background: $primary-color !important;
      color: $white !important;
      text-transform: uppercase;
      //border: 2px solid $white;
      //border-radius: 100px;
      width: 150px;

      &:hover {
        background-color: #fff !important;
        color: $primary-color !important;
        border: 2px solid $primary-color;
      }

      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
}

p.plan-info__availability {
  font-size: 1rem;

  span {

    /*&:after{
      content: '°';
    } */
    &:not(:first-of-type):before {
      content: ' - ';
    }
  }
}

.plan-list .plan-wrapper .plan-image .plan-image__data.plan-image__data--views {
  // hide views
  display: none;
}

// PAGE FLOOR
.building-map {
  .main-wrapper {
    //flex: 0 0 100% !important;
    margin: 0 !important;
  }
}

// PAGE RIEPILOGO
.riepilogo__wrapper {
  .section-costs {
    background-color: $primary-color !important;

    //border-radius: 0 0 0 10px;
    .section-costs__field {
      .section-costs__field--label {
        color: $black !important;
      }

      .section-costs__field--price {
        color: $secondary-color !important;
      }
    }
  }
}

// OFFCANVAS CART
.offcanvas-cart__button {
  @include icon(35px 35px, no-repeat, center, 75px, 70px);
  background-color: $primary-color;

  &:after {
    color: $white;
  }
}

// FOOTER
.footer__links {
  font-family: $secondary-font;

  a {
    margin: 0 0.5rem;
    font-weight: 400;
    opacity: 0.5;
  }

  .footer-title {
    font-weight: 600;
  }

  p {
    font-size: 0.75rem;
    color: #767676;
  }

  .listPolicies {
    margin-left: 100px;
    margin-right: 120px;
  }
}

.partner__container {
  font-family: $secondary-font;
  font-size: 0.7rem;
  margin: 0 1.2rem;

  @media screen and (max-width: 991px) {
    text-align: center;
  }
}

.quote-number-container.non-disponibile {
  background-color: red;
  filter: none;
  bottom: 165px;
}

.save-and-close {
  border-radius: 6px;
  border-color: unset;
}

.save-and-close:hover {
  border-radius: 6px;
  border-color: unset;
  border-style: revert;
}

.white-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  background-color: white;
  top: 0;
  left: 0;
}

.offcanvas-cart__wrapper {
  .button--cart.active {
    border-width: 5px;
  }
}