@use "../../theme/utils/variables" as *;

#tecma-floatingContent {
  position: relative;
  z-index: 99999;
  .tecma-floatingContent-container {
    position: absolute;
  }
}
.floatingContent-trigger {
  display: inline-block;
}
