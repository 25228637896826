@use "../../theme/utils/variables" as *;

$outline-size: 0.063rem;
@keyframes hoverDelay {
  0% {
    visibility: hidden;
  }
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.tecma-tooltip {
  max-width: 16rem;
  background-color: $primary;
  color: $neutral-100;
  text-align: center;
  padding: 0.25rem;
  border-radius: 0.25rem;

  &.hover {
    animation: hoverDelay 1s;
  }

  &.secondary {
    background-color: $secondary;
  }

  &.outlined {
    background-color: $neutral-100;
    color: $primary;
    outline: $outline-size solid $primary;
    &.secondary {
      color: $secondary;
      outline: $outline-size solid $secondary;
    }
  }

  &.arrow {
    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-bottom: 0.5rem solid $primary;
      border-right: 0.5rem solid transparent;
    }
    &.secondary::before {
      border-bottom-color: $secondary;
    }
    &.outlined {
      &::before {
        border-bottom-color: $neutral-100;
        outline: $outline-size solid $primary;
        clip-path: polygon(
          -($outline-size) (-($outline-size)),
          calc(100% + $outline-size) calc(100% + $outline-size),
          -($outline-size) calc(100% + $outline-size)
        );
      }
      &.secondary::before {
        outline-color: $secondary;
      }
    }
    &.top-center {
      margin-bottom: 0.5rem;
      &::before {
        bottom: 0.25rem;
        left: 50%;
        transform: translate(-50%, 0) rotate(-45deg);
      }
    }
    &.bottom-center {
      margin-top: 0.5rem;
      &::before {
        top: 0.25rem;
        left: 50%;
        transform: translate(-50%, 0) rotate(135deg);
      }
    }
    &.right {
      margin-left: 0.5rem;
      &::before {
        left: 0.25rem;
        top: 50%;
        transform: translate(0, -50%) rotate(45deg);
      }
    }
    &.left {
      margin-right: 0.5rem;
      &::before {
        right: 0.25rem;
        top: 50%;
        transform: translate(0, -50%) rotate(-135deg);
      }
    }
  }
}
