// NAV CONFIGURATOR
.nav--configurator {
  ul {
    background: $tertiary-color;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    margin-top: 0;
    li {
      display: inline-block;
      padding: 0 1rem 0 1.5rem;
      line-height: 0.7;
      &.current a {
        pointer-events: none;
      }
      a {
        color: $white;
        font-weight: $bold;
        font-size: 1rem;
        background-color: $primary-color;
        padding: 0.6rem 0.5rem;
        @media screen and (max-width: $small-desktop) {
          font-size: 1.3rem;
        }
      }
      &:not(:last-child):after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 0 4px 8px;
        border-color: transparent transparent transparent $black;
        float: right;
        position: relative;
        left: 1rem;
        top: 0.2rem;
        @media screen and (max-width: $small-desktop) {
          top: 0.3rem;
        }
      }
      &:not(.current) {
        opacity: 0.5;
        a {
          color: $primary-color;
          background-color: inherit;
          padding: 0;
        }
      }
      &.disabled {
        cursor: text;
        pointer-events: none;
      }
      span {
        font-weight: $normal;
      }
    }
  }
}

// NAV PAGINATION

.nav--pagination {
  position: relative;
  margin: 2rem 0;
  @media screen and (max-width: $mobile) {
    margin: auto !important;
    padding: 0 1rem;
    width: 80%;
  }
  > * {
    text-align: center;
    display: table;
    margin: 0 auto;
    color: $black;
    font-weight: $normal;
    position: relative;
    h5 {
      text-transform: uppercase;
      color: $black;
      font-size: 1rem;
      font-weight: $normal;
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 1rem;
  }
}

// NAV PAGINATION PROGRESS

.progress {
  background: rgba($tertiary-color, 0.2);
  padding: 1rem;
  border-radius: 50%;
  transform: rotate(270deg) scale(0.35);
  position: absolute;
  left: 40.25%;
  top: -66px;
}

.progress__value {
  background-color: rgba($tertiary-color, 0.2);
  border-radius: 50%;
  position: relative;
  width: 50px;
  height: 50px;
  min-width: 50px;
  top: 13px;
  .CircularProgressbar-path {
    stroke: $black;
    fill: none;
    stroke-width: 3;
    // 5 steps
    &.progress-1-of-5 {
      stroke-dasharray: 20, 100;
    }
    &.progress-2-of-5 {
      stroke-dasharray: 40, 100;
    }
    &.progress-3-of-5 {
      stroke-dasharray: 60, 100;
    }
    &.progress-4-of-5 {
      stroke-dasharray: 80, 100;
    }
    &.progress-5-of-5 {
      stroke-dasharray: 100, 100;
    }
    // 6 steps
    &.progress-1-of-6 {
      stroke-dasharray: 16, 100;
    }
    &.progress-2-of-6 {
      stroke-dasharray: 32, 100;
    }
    &.progress-3-of-6 {
      stroke-dasharray: 48, 100;
    }
    &.progress-4-of-6 {
      stroke-dasharray: 64, 100;
    }
    &.progress-5-of-6 {
      stroke-dasharray: 82, 100;
    }
    &.progress-6-of-6 {
      stroke-dasharray: 100, 100;
    }
  }
  .page-progress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
