// BUTTON ROTATE
.round-button--rotate {
  border-radius: 50%;
  top: 1rem;
}

.home-configurator.login {
  @extend %background-image;
  @extend %background-image--overlay;
  background-size: cover;
  width: 100%;
  //height: 100vh;
  min-height: 100vh;
}

.login__wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 4rem 0;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  max-width: 1000px;
  text-align: center;
  &.register-form {
    .primary-logo {
      display: none;
    }
  }
  > * {
    flex: 0 0 100%;
  }
  h1 {
    color: $white;
    font-weight: $normal;
  }
  .login-form__wrapper {
    flex: 0 0 320px;
    min-height: 270px;
    background-color: $white;
    margin: 0 auto 2rem auto;
    padding: 2rem 2rem 2rem 2rem;
    box-sizing: border-box;
    &.register {
      min-height: calc(450px + 2rem);
      height: auto;
      margin-top: 2rem !important;
      flex: 0 0 630px;
    }
  }
  hr {
    flex: 0 0 50%;
  }
  label {
    font-size: 0.9rem;
    font-weight: 700;
    padding: 0 0 0.7rem 0;
    display: block;
  }
  form#email-form > div:not(.input-wrap) {
    font-size: 0.85rem;
    color: $white !important;
    font-weight: 700;
    padding: 1rem 0 0 0;
    margin: 0 !important;
    display: block;
  }
  input {
    width: 100%;
    box-sizing: border-box;
    margin: 0.7rem 0 0 0;

    padding: 0.2rem 0.5rem;
    border: 0;
    &:focus {
      outline: none;
    }
    &.w-input {
      height: 35px;
      font-size: $paragraph-size;
      color: $text-color;
      border: 1px solid lighten($black, 80%);
      &.checked {
        height: 15px !important;
        width: 10% !important;
        margin-bottom: 10px !important;
        margin-left: 0 !important;
      }
      &.checked-register {
        height: 15px !important;
        width: 2% !important;
        margin-bottom: 10px !important;
        margin-right: 5px !important;
        margin-left: 11px !important;
      }
      &.register {
        width: 50% !important;
        display: flex !important;
        flex-grow: 1 !important;
        margin-left: 12px !important;
      }
    }
    &#vendor-autocomplete {
      height: 35px;
      //color: $text-color;
      border: 1px solid lighten($black, 80%);
      font-family: p22-underground, sans-serif;
      font-size: 0.9rem !important;
      background-color: transparent !important;
      border-color: #fff !important;
    }
    &.w-button {
      background: $black;
      color: $white;
      margin: $sm-space 1rem 0 1rem;
      padding: 0.5rem 0;
      transition: opacity 0.3s;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .register-privacy-text {
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    &.check-privacy {
      margin-top: 15px;
      margin-bottom: 15px;
      flex-direction: column;
      align-items: flex-start;
      .privacy-text {
        font-size: 13px;
      }

      .w-checkbox-privacy-container {
        margin-top: 10px;
        width: 100%;
        display: flex;
        align-items: center;

        &:first-child {
          margin-top: 0;
        }
        span {
          width: 100%;
        }

        a {
          color: inherit;
          border-bottom: 1px solid white;
        }
      }
    }
  }
}

.react-autosuggest__container {
  position: relative;
  text-align: left !important;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 100;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 46px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  max-height: 190px;
  overflow-y: overlay;
  width: 255px;
  font-family: p22-underground, sans-serif;
  font-size: 0.9rem !important;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
