@use "../../theme/utils/variables";

$extra-small: 1rem;
$small: 1rem;
$medium: 1.5rem;
$large: 2rem;
$extra-large: 2rem;

.tecma-icon {
  &.extra-small {
    width: $extra-small;
    height: $extra-small;
  }

  &.small {
    width: $small;
    height: $small;
  }

  &.medium {
    width: $medium;
    height: $medium;
  }

  &.large {
    width: $large;
    height: $large;
  }

  &.extra-large {
    width: $extra-large;
    height: $extra-large;
  }
}
