@import './mixin.scss';

// GENERIC INPUT
.cms-input {
  width: 100%;
  .cms-input-title {
    @include text($color: black, $fontSize: 16px, $fontWeight: 700);
  }

  .cms-input-description {
    margin-top: 3px;
    font-style: italic;
    @include text($color: black, $fontSize: 12px, $fontWeight: 300);
  }
}

// TEXT INPUT
.cms-text-input-container {
  input {
    margin-top: 10px;
    border: 1px solid #909090;
    border-radius: 1px;
    padding: 16px;
    width: 200px;
    font-size: 16px;
    line-height: 21px;
  }
  input[disabled] {
    opacity: 0.6;
  }
  &.with-check {
    display: flex;
    gap: 8px;
  }
  .cms-checkbox-with-title-container {
    flex-grow: 0.05;
  }
  &.invalid-input {
    .cms-input-title,
    .cms-input-description {
      color: red;
    }
    input {
      border-color: red !important;
    }
  }
}

// RADIO BUTTON INPUT
.cms-radio-buttons-container {
  .cms-all-radios {
    margin-top: 10px;

    &.single-row {
      display: flex;
      align-items: center;

      .single-checkmark-container {
        display: inline-flex;
        justify-content: center;
        &:not(:last-child) {
          margin-right: 10px;
          margin-bottom: unset;
        }
      }
    }

    .single-checkmark-container {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      span.checkmark {
        height: 18px;
        width: 18px;
        background-color: white;
        border: 1px solid #7c7c7c;
        border-radius: 50%;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 12px;
          width: 12px;
          border-radius: 50%;
        }
      }

      &.checked {
        span.checkmark {
          border-color: #6bc1b4;
          &:before {
            background-color: #6bc1b4;
          }
        }
        .checkmark-label {
          font-weight: 700;
        }
      }

      span.checkmark-label {
        @include text($fontSize: 14px, $fontWeight: 400, $color: #5b5b5b);
        margin-left: 5px;
      }
    }
  }
}

// SELECT INPUT
.cms-select-container {
  .cms-custom-select-container {
    display: inline-block;
    min-width: 300px;
    position: relative;
    margin-top: 10px;

    .cms-custom-select {
      background-color: white;
      border-bottom: 1px solid #909090;
      padding: 10px 15px;
      cursor: pointer;
      @include text($color: black, $fontSize: 14px, $fontWeight: 400);

      span.icon {
        position: absolute;
        right: 0;
        transition-duration: 0.2s;
        transition-property: transform;
      }
    }

    ul.cms-custom-select-options {
      position: absolute;
      z-index: 1;
      background-color: white;
      border: 1px solid #d3d3d3;
      list-style: none;
      padding: 0px;
      margin: 0;
      width: 100%;
      max-height: 300px;
      overflow: auto;

      li.cms-custom-select-option {
        padding: 10px 15px;
        cursor: pointer;

        &:hover {
          background-color: #eeeeee;
        }
      }
    }
  }
}

// File input
.cms-file-input-container,
.cms-template-container {
  display: flex;
  justify-content: space-between;

  &.type-video {
    .cms-file-input-right,
    .cms-template-right {
      justify-content: flex-end;
    }
  }

  .button-cms {
    margin-top: 15px;
    width: auto;
    display: inline-block;
    text-align: center;
    padding: 14px 32px;
  }

  .cms-file-input-right,
  .cms-template-right {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
  }

  img.preview {
    width: 100px;
    height: 70px;
    object-fit: fill;
    border-radius: 2px;
  }

  div.icon.trash {
    cursor: pointer;
  }

  .cms-file-input-preview {
    display: flex;
    align-items: center;
    background-color: #f4f4f5;
    border-radius: 1px;
    padding: 8px 24px;
    margin-top: 10px;

    span.cms-file-name-container {
      display: flex;
      flex-grow: 1;
      padding: 0px 10px;
      @include text($color: #000000, $fontSize: 12px, $fontWeight: 400);
    }
  }

  .cms-template-preview {
    display: flex;
    align-items: center;
    background-color: #f4f4f5;
    border-radius: 1px;
    padding: 0px 20px;
    margin-top: 10px;

    span.cms-file-name-container {
      display: flex;
      flex-grow: 1;
      padding: 0px 10px;
      @include text($color: #000000, $fontSize: 12px, $fontWeight: 400);
    }
  }

  .cms-file-input-left {
    &.invalid-input {
      .cms-file-input-title,
      .cms-file-input-description {
        color: red;
      }
    }
  }

  .cms-template-left {
    &.invalid-input {
      .cms-template-title,
      .cms-template-description {
        color: red;
      }
    }
  }
}

// number input (decrement increment)
.cms-number-increment-decrement {
  display: flex;
  align-items: center;
  margin-top: 10px;
  span,
  input {
    display: inline-flex;
    margin: 0px !important;
    padding: 0px !important;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  input {
    height: 40px;
    width: 56px;
    font-family: 'SegoeRegular', sans-serif;
    border: 1px solid #d3d3d3;
    background: #ffffff;
    font-size: 16px;
    font-weight: 700;
  }

  span {
    height: 40px !important;
    width: 40px !important;
    background-color: #f4f4f5;
    border: 1px solid #d3d3d3;
    cursor: pointer;
    background-size: 15px;

    &.disabled {
      opacity: 0.2;
    }

    &:first-child {
      border-right: none;
    }

    &:last-child {
      border-left: none;
    }
  }
}

// checkbox
.cms-checkbox-container {
  display: inline-flex;

  &.cms-checkbox-container-with-title {
    margin-top: 5px;
  }

  .cms-checkbox {
    width: 22px;
    height: 22px;
    border: 1px solid #909090;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    &.checked {
      background-color: #6bc1b4;
      border-color: transparent;
    }

    .check-white {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

// color picker
span.color-picker {
  border: 3px solid white;
  border-radius: 2px;
  height: 32px;
  width: 32px;
  cursor: pointer;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  position: relative;

  .color-picker-popup {
    cursor: default;
    position: absolute;
    z-index: 3;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.35);
    background-color: white;
    left: -250px;
    top: -100px;
    border-radius: 6px;

    .color-picker-cover {
      width: 230px;
      height: 180px;
      padding: 10px;

      .color-picker-saturation {
        width: 100%;
        height: 100%;
        position: relative;
        cursor: pointer;

        .color-picker-saturation-white,
        .color-picker-saturation-black {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }

        .color-picker-saturation-white {
          background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
        }

        .color-picker-saturation-black {
          background: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
        }

        .color-picker-saturation-pointer {
          height: 8px;
          width: 8px;
          position: absolute;
          background-color: white;
          border: 1px solid black;
          border-radius: 50%;
        }
      }
    }

    .color-picker-controls {
      padding: 10px 10px;
      padding-top: 0px;
      .color-picker-horizontal {
        cursor: pointer;
        height: 16px;
        background: linear-gradient(to right, #f00 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, #f00 100%);
        position: relative;

        .color-picker-horizontal-pointer {
          height: 20px;
          width: 6px;
          position: absolute;
          top: -2px;
          background-color: white;
          border: 1px solid black;
          border-radius: 3px;
        }
      }
    }

    .color-picker-color-preview {
      padding: 10px 10px;
      padding-top: 0px;
      display: flex;

      span.color-current-color-preview {
        width: 40px;
        height: 40px;
        border: 1px solid #c4c4c4;
        box-sizing: border-box;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      input[type='text'] {
        display: inline-flex;
        height: 40px;
        width: auto;
        flex-grow: 1;
        box-sizing: border-box;
        border: 1px solid #c4c4c4;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-left: none;
        font-family: 'SegoeRegular', sans-serif;

        @include text($color: black, $fontSize: 12px, $fontWeight: 400);
      }
    }

    .color-picker-default-colors {
      padding: 0px 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 230px;
      > span {
        cursor: pointer;
        height: 40px;
        width: 40px;
        border-radius: 3px;
        margin-bottom: 10px;
      }
    }
  }
}

//Decrement Increment
.cms-number-increment-decrement-container {
  &.invalid-input {
    .cms-input-title,
    .cms-input-description {
      color: red;
    }
  }
}
