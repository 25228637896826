// FOOTER
footer {
  background-color: $background-color;
  margin-top: $sm-space;
  position: relative;
  z-index: 2;
  > div {
    border-top: 2px solid $white;
  }
}

//pre-footer
.pre-footer {
  .footer__content {
    @extend %content-layout;
    display: flex;
    justify-content: center;
    align-items: center;
    .footer__location-box {
      padding: $lg-space;
      border: 1px solid $primary-color;
      margin: 0 $md-space;
      position: relative;
      box-sizing: border-box;
      .location-box__icon {
        @include icon(cover, no-repeat, center, 50px, 50px);
        background-color: $primary-color;
        display: block;
        position: absolute;
        @extend %center-absolute-XY;
        top: 0;
      }
    }
  }
}

// disclaimer
.disclaimer {
  .footer__content {
    @extend %content-layout;
    font-size: $disclaimer-font-size;
    line-height: $disclaimer-line-height;
    margin-bottom: 0;
    a {
      font-size: $disclaimer-link-size;
      line-height: $disclaimer-line-height;
      color: $black;
      /* &:not(:last-child):after {
        content: "  -  ";
      } */
    }
  }
}

// TABLET and less
@media screen and (max-width: $small-desktop) {
  // Pre-footer
  .pre-footer {
    .footer__content {
      flex-wrap: wrap;
      > div {
        flex: 0 0 100%;
        margin: $sm-space 0;
        &.footer__location-box {
          margin: $lg-space 0;
        }
      }
    }
  }
}