@font-face {
  font-family: SegoeRegular;
  font-weight: 400;
  src: url('../../fonts/SegoeUI.ttf') format('truetype');
}

@font-face {
  font-family: SegoeRegular;
  font-weight: 100;
  src: url('../../fonts/SegoeUI-light.ttf') format('truetype');
}
