body.rotated .MuiPaper-root{
    
    max-height: 220px!important;
    transform: rotate(180deg) translateY(0px) !important;
    transform-origin: 50% 50% !important;
}

body.rotated #floorplanning-app .offcanvas-cart.amenities {
    .offcanvas-cart__wrapper-amenities {
        transform: rotate(180deg);

        .modal-container {
            height: 100%;
            display: flex;
            align-items: flex-end;

            .dotazioni {
                margin-bottom: 150px!important;
            }
        }
    }

    .offcanvas-cart__button.amenities {
        transform: rotate(180deg)!important;
        border-radius: 90px 0px 0px 90px !important;
        bottom: 250px!important;
        top: unset!important;
    }
}

@media print {
    header, .offcanvas-cart, .sidebar, .riepilogo__wrapper, .nav--pagination, .nav--configurator, .footer-notes {
        display: none !important;
    }
}