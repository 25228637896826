.loader-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(51, 51, 51, 0.8);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11111111;

  &.white {
    background-color: white;
    .spinner {
      > div {
        background-color: #333;
      }
    }
  }

  .spinner {
    text-align: center;

    > div {
      width: 18px;
      height: 18px;
      background-color: white;

      &:not(:last-child) {
        margin-right: 5px;
      }

      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.progress-bar-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(51, 51, 51, 0.8);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11111111;
  color: white;

  .progress-bar {
    width: 70%;
    height: 10px;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;

    .progress-bar-progress-container {
      background-color: #6bc1b4;
      height: 100%;
    }
  }

  > div:not(:first-child) {
    margin-top: 10px;
  }
}
