@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.0.0/animate.min.css");

@keyframes slideInDown {
  0% {
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
      visibility: hidden
  }
  to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      visibility: visible
  }
}
  
  // SLIDE IN-UP
  @keyframes slideInUp {
    from {
      transform: translate3d(0, 150%, 0);
      visibility: visible;
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  .slideInUp {
    animation-name: slideInUp;
  }

  // Button animation
@keyframes cart-button {
  0% {
    right: -70px;
  }
  50% {
    right: -75px;
  }
  100% {
    right: -70px;
  }
}