.cms-sidebar {
  background-color: white !important;
  width: 840px;
  display: flex;
  flex-flow: column;

  .cms-sidebar-body-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .cms-sidebar-content {
    position: relative;

    width: 100%;
    height: 100%;

    > .loader-container {
      position: absolute;
      top: 0;
      left: 0;
      transform: unset;
    }

    .cms-sidebar-body {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .cms-sidebar-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .cms-sidebar-close-button {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      padding: 16px 4px 0 0;
      .close-button:hover {
        background-color: transparent;
      }
    }
  }
}
