// OFFCANVAS CART
.offcanvas-cart {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  transform: translateX(-250px);
  transition: transform 0.5s;
  z-index: 3;
  &.open {
    transform: translateX(0);
    .offcanvas-cart__button {
      animation: none;
    }
  }

  .offcanvas-cart__wrapper {
    background-color: $primary-color; // placeholder
    width: 250px;
    height: 100%;
    padding: $lg-space $md-space;
    box-sizing: border-box;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
  }
  .offcanvas-cart__button {
    display: block;
    border-radius: 0 50% 50% 0;
    position: absolute;
    top: 250px;
    right: -75px;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
    animation: cart-button infinite 1.5s;
    cursor: pointer;
    &:after {
      font-size: 0.7rem;
      position: absolute;
      top: 29px;
      left: -5px;
      transform: rotate(-90deg);
    }
  }
  .offcanvas-cart__button.keyplan{
    display: block;
    border-radius: 0 50% 50% 0;
    position: absolute;
    top: 160px;
    right: -75px;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
    // animation: cart-button infinite 1.5s;
    cursor: pointer;
    &:after {
      content: "STATO";
      font-size: 0.7rem;
      position: absolute;
      top: 29px;
      left: -5px;
      transform: rotate(-90deg);
    }
  }
}

/*.offcanvas-cart.model.keyplan{
  transform: translateX(-259px) !important;
}*/


// OFFCANVAS x PLANIMETRIE

.offcanvas-cart__wrapper-planimetrie {
  background-color: $white;
  height: 100%;
  box-sizing: border-box;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
  width: auto !important;
  padding: 0rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .views-wrapper__plan {
    text-align: center;
    display: table;
    margin: .5rem auto;
  }
  .views-wrapper__plan > div {
    display: table-cell;
    vertical-align: middle;
  }
  .views-wrapper__title span{
    font-size: 1.3rem;
  }
}

.offcanvas-cart__button.offcanvas--planimetrie{
  background-position: 15px 15px;
  @media screen and (max-width: 992px) {
    display:none;
  }
  &:after{
    content: none;
  }
}
.offcanvas-cart.model{
  z-index: 1 !important;
}


.planimetry-wrapper {
  display: block !important;
  flex-direction: row !important;

  .buildings-name-container {
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 10px;
  }
}
.planimetry-wrapper-name {
  margin-left: 10px!important;
  font-weight: 700 !important;
}
.planimetry-wrapper-floor-name {
  flex-direction: column !important;
  margin-top: 30px !important;
}
.planimetry-floor {
  flex-direction: column-reverse!important;
  display: inline-flex !important;
  margin-right: 10px !important;
}
.planimetry-floor-name-building {
  display: flex !important;
  justify-content: space-between !important;
}
.view-icon.empty-block.model {
  background-color: #d6d6d6 !important;
}
.views-wrapper__keyplan {
  min-width: 500px !important;
  min-height: 500px !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center;
}
.offcanvas-cart__button.offcanvas--planimetrie.keyplan{
  @media screen and (max-width: 992px){
    display:none !important;
  }
  background-position: 15px 15px;
  &:after{
    content: none;
  }
}


.custom-offcanvas {
  z-index: 51;
  transition: all 0.5s ease 0s;
  transform: translate3d(0px, 0px, 0px);

  &.open {
    z-index: 52;
  }

  &.closed {
    transform: translate3d(-100%, 0px, 0px);
  }
}

.modal.full-page.VISTE {
  .modal__content {
    .content__image {
      height: 100%;
      width: 100%;
      background-size: contain!important;
      margin: 0 auto!important;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}