%background-image{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

%background-image--overlay{
    background-color: rgba($black, 0.5);
    background-blend-mode: overlay;
}
