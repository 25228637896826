@use "../../../../theme/utils/variables.scss" as *;

.date-value {
  padding: 0.85rem 0.2rem;
  overflow-y: hidden;
  position: relative;
  .inner-value {
    display: block;
  }
  &:first-of-type::after {
    content: "";
    background-color: $background-color-1;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5;
  }
  &.day {
    padding: 0.875rem 0rem 0.875rem 0.5rem;
  }
  &.month {
    text-transform: uppercase;
    width: 5.5rem;
    min-width: 5.5rem;
    padding-right: 0.5rem;
  }
  &.year {
    padding: 0.875rem 0.5rem;
  }
  &-arrows {
    display: flex;
    flex-direction: column;
    width: 1.875rem;
    border-left: 0.0625rem solid $neutral-90;
    .date-value-arrow {
      border-radius: 0;
      background-color: inherit;
      .tecma-icon path {
        fill: black;
      }
      &.disabled {
        .tecma-icon path {
          fill: $neutral-80;
        }
      }
    }
  }
}
