@use "../../theme/utils/variables" as *;

.tecma-modal-container {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  position: fixed;
  inset: 0;
  z-index: 9999;
  background-color: rgba($color: $neutral-000, $alpha: 0.5);

  &.top {
    align-items: flex-start;
  }
  &.center {
    align-items: center;
  }
  &.bottom {
    align-items: flex-end;
  }

  .tecma-modal {
    box-sizing: border-box;
    position: absolute;
    width: 25rem;
    min-width: 10rem;
    max-width: fit-content; //useful for mobile -> to be verified
    background-color: $neutral-100;
    padding: 1rem;
    border-radius: 0.25rem;
    box-shadow: 0 0 2px 0px $secondary;

    &.small {
      width: 20rem;
    }
    &.medium {
      width: 40rem;
    }
    &.large {
      width: 60rem;
    }

    &-header {
      display: flex;

      &.closeIcon {
        justify-content: space-between;

        .tecma-button {
          padding: 0;
        }
      }
    }

    &-content {
      max-height: 60vh;
      overflow: auto;
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
      align-self: center;
      flex-wrap: wrap;
      gap: 0.5rem;

      &.noWrapAndCenter {
        //useful for dual button layout or no wrap in general
        justify-content: space-around;
        flex-wrap: nowrap;
      }
    }
  }
}
