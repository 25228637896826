@import './mixin.scss';

.modal-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999999999;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-content-container {
  outline: none;
  font-family: 'SegoeRegular', sans-serif;
  max-width: 650px;
  width: 650px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fdfdfd;
  border-radius: 8px;
  padding: 50px 80px;
  text-align: center;

  .modal-title {
    @include text($fontSize: 42px, $fontWeight: 300, $color: black);
  }

  .modal-subtitle {
    margin-top: 10px;
    @include text($fontSize: 18px, $fontWeight: 300, $color: #909090);
  }

  .modal-buttons-container {
    margin-top: 20px;
    @include flexContainer($justify-content: center);
    & > * {
      min-width: 150px;
      height: 40px;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}

.cms-modal.generic-modal {
  z-index: 100000000000;
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: #fdfdfd;
  transform: translate(-50%, -50%);
  border-radius: 1px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  width: 600px;
  text-align: center;
  padding: 50px 100px;
  font-family: 'SegoeRegular';
  .cms-modal-close {
    position: relative;
    span {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }
  }

  .cms-modal-title {
    margin-bottom: 16px;
    @include text($color: #333333, $fontSize: 42px, $fontWeight: 100);
  }

  .cms-modal-description {
    margin-bottom: 40px;
    @include text($color: #909090, $fontSize: 18px, $fontWeight: 100);
  }

  .cms-modal-buttons {
    display: flex;
    justify-content: center;
    .button-cms {
      padding: 15px 35px;
      margin-top: 0;
      @include text($fontWeight: bold, $fontSize: 14px, $color: white);

      &.button-inverted {
        @include text($fontWeight: bold, $fontSize: 14px, $color: #909090);
      }

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}

.cms-modal.generic-modal.fullscreen-image-modal {
  background-color: rgba(51, 51, 51, 0.8);
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  transform: none;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 90%;
    height: 90%;
    object-fit: contain;
  }

  .close-button {
    position: absolute;
    top: 2%;
    right: 2%;
    background-color: transparent;
  }
}

.cms-modal.generic-modal.warning-css-edit {
  padding: 20px 30px;
}
