$button-edit--size: 25px;
$button-edit--bg: lighten($black, 93%);
%button-sections__separator{
  content: '';
    height: 100%;
    width: 1px;
    display: inline-block;
    background: lighten($black, 80%);
    position: relative;
    left: 25%;
}

.nav-edit {
  background-color: #fff;
  position: fixed;
  height: 150px;
  width: 110%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 50%;
  border-bottom-left-radius: 60%;
  border-bottom-right-radius: 60%;
  box-shadow: 8px 8px 13px 0 rgba(0, 0, 0, 0.1);
  @extend %center-absolute-X;
}

.nav-edit__wrapper {
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.button-edit__section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section--button-edit-color {
  background-color: $button-edit--bg;
  border-radius: 20px;
  .button-color {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 0 10px;
    &.active {
      width: 25px;
      height: 25px;
    }
    &.button-color--black {
      background-color: $black;
    }
    &.button-color--blue {
      background-color: #1f45db;
    }
    &.button-color--azure {
      background-color: #56b7f0;
    }
    &.button-color--yellow {
      background-color: #ffd000;
    }
    &.button-color--green {
      background-color: #7ab943;
    }
    &.button-color--red {
      background-color: #e90000;
    }
  }
  &:after{
    @extend %button-sections__separator;
  }
}

.section--button-edit-size {
  background-color: $button-edit--bg;
  border-radius: 20px;
  .button-size {
    background-color: darken($background-color, 20%);
    border-radius: 50%;
    margin: 0 10px;
    &.button-size--5 {
      width: $button-edit--size;
      height: $button-edit--size;
    }
    &.button-size--4 {
      width: calc($button-edit--size/1.2);
      height: calc($button-edit--size/1.2);
    }
    &.button-size--3 {
      width: calc($button-edit--size/1.4);
      height: calc($button-edit--size/1.4);
    }
    &.button-size--2 {
      width: calc($button-edit--size/1.6);
      height: calc($button-edit--size/1.6);
    }
    &.button-size--1 {
      width: calc($button-edit--size/1.8);
      height: calc($button-edit--size/1.8);
    }
  }
  &:after{
    @extend %button-sections__separator;
  }
}

.button-edit {
  @include icon(60%, no-repeat, center, 35px, 35px);
  background-color: $button-edit--bg;
  border: 1px solid darken($background-color, 10%);
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
  &.button-edit--clear {
    position: relative;
    bottom: 2rem;
  }
  &.button-edit--pen {
    margin-left: 0;
  }
  &.button-edit--text {
    margin-left: 0;
  }
}
.nav-edit__wrapper .button-edit--clear {
  bottom: 0;
}

.section--button-edit-text {
  background-color: $button-edit--bg;
  border-radius: 20px;
  .button-text {
      @include icon(40%, no-repeat, center, 35px, 35px);
      width: $button-edit--size;
      height: $button-edit--size;
      margin: 0 5px;
  }
}