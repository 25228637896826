@import './../../common/mixin.scss';
@import './../../common/config.scss';

.cms-settings-page {
  position: fixed;
  top: 72px;
  left: 0;
  width: 100%;
  height: calc(100% - 72px);
  z-index: 1298;
  background-color: white;
  flex-wrap: nowrap;
  display: flex;

  > div {
    height: 100%;
    display: inline-flex;
  }

  .cms-settings-preview-container {
    flex-grow: 1;
    background-color: #f9f9f9;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px;
    overflow: auto;

    .single-preview-container-wrapper {
      flex: 50%;
      box-sizing: border-box;
      padding: 10px;

      .single-preview-container {
        height: 100%;
        width: 100%;
        background-color: #ffffff;
        border: 1px solid #d3d3d3;
        padding: 20px 30px 30px 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .preview-title {
          @include text($color: black, $fontWeight: 400, $fontSize: 20px);
        }

        .preview-divisor {
          height: 1px;
          background-color: #d3d3d3;
          margin: 10px 0px;
          border: none;
        }

        .preview-container {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          justify-content: space-evenly;
        }
      }
    }
  }

  // text previews
  .text-preview {
    .preview-text-title {
      font-weight: 400;
      font-size: 42px;
    }

    .preview-text-subtitle {
      font-weight: 400;
      font-size: 28px;
    }

    .preview-text-description {
      font-weight: 400;
      font-size: 14px;
    }
  }

  // buttons preview
  .buttons-preview {
    .secondary-buttons-container {
      display: flex;

      > div {
        flex-basis: 50%;
      }
    }

    .buttons-descriptions {
      @include text($color: #000000, $fontWeight: 400, $fontSize: 12px);

      margin-bottom: 5px;
    }

    .button-container {
      .button-cms {
        width: 120px;
        height: 47px;
        padding: 0;
        display: inline-flex;
      }
    }

    .arrow-buttons {
      .button-cms {
        width: 50px;
        display: inline-flex;

        &:not(:first-child) {
          margin-left: 20px;
        }
      }
    }
  }

  // preview navbar NS
  .navbar-preview {
    .navbar-example {
      width: 66px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 20px;

      .navbar-example-icon {
        display: inline-flex;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid white;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        cursor: pointer;

        &:not(:first-child) {
          margin-top: 10px;
        }

        &.back-icon {
          span {
            background-size: 10px;
          }
        }

        &.refresh-icon,
        &.disambiguation-icon {
          border: none;
          span {
            background-size: 100%;
            width: 40px;
            height: 40px;
          }
        }

        &.no-tap-icon {
          span {
            background-size: calc(100% - 4px);
          }
        }
      }
    }
  }

  // setter section
  .cms-settings-container {
    width: 100%;
    max-width: 380px;
    border-left: 1px solid #d3d3d3;
    display: inline-flex;
    flex-grow: 1;
    flex-direction: column;
    position: relative;
    overflow: auto;

    .top-bar-container {
      padding: 30px;

      .top-bar-title-container {
        display: flex;
        align-items: center;
        span {
          display: inline-flex;

          &.top-bar-title {
            margin-left: 10px;
            @include text($color: black, $fontWeight: 400, $fontSize: 28px);
          }
        }
      }

      .settings-top-bar-switcher-container {
        margin-top: 30px;
      }
    }

    .settings-action-buttons-container {
      display: flex;
      flex-grow: 1;
      align-items: flex-end;
      justify-content: space-between;
      padding: 50px;
      flex-wrap: wrap;

      .button-cms {
        box-sizing: border-box;
        margin-top: 10px;
        padding: 16px 40px;

        @include text($fontWeight: 700, $fontSize: 14px, $color: #909090);

        &:not(.button-inverted) {
          color: white;
        }
      }
    }

    .settings-menu {
      .settings-item {
        background-color: white;
        padding: 20px 50px;
        flex-wrap: wrap;
        line-height: 28px;
      }

      &.font-settings {
        .main-description {
          display: inline-flex;
          font-weight: 700;
          align-items: center;
        }
      }

      &.design-settings {
        .radio-container-design {
          padding-left: 20px;

          .single-checkmark-container {
            .checkmark-label {
              display: flex;
              flex-grow: 1;

              > span:first-child {
                display: flex;
                flex-grow: 1;
              }

              .design-elem {
                display: inline-block;
                width: 12px;
                height: 12px;
                border-right: none;
                border-bottom: none;
                border-left: 2px solid #dbdbdb;
                border-top: 2px solid #dbdbdb;

                &.design--squared {
                  border-color: #909090;
                }

                &.design--round50 {
                  border-top-left-radius: 4px;
                }

                &.design--round100 {
                  border-top-left-radius: 20px;
                }
              }
            }
          }
        }
      }

      .settings-item {
        &.single-line {
          display: flex;
          align-items: center;
        }

        span {
          display: inline-flex;

          &.icon-color {
            border-radius: 50%;
            background-color: #d3d3d3;
            margin-right: 10px;

            &.selected {
              background-color: #1c8576;
            }
          }

          &.main-description {
            flex-grow: 1;
            display: flex;
          }
        }
      }
    }
  }
}
