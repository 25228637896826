@use "../../theme/utils/variables" as *;

.tecma-accordion {
  .tecma-accordion-content {
    .custom-button {
      box-sizing: border-box;
      border: none;
      align-items: center;
      padding: 0;
      font-size: $smallFont;
      font: inherit;
      width: 100%;
      cursor: pointer;
      background-color: transparent;

      .icon {
        padding: 0.438rem 0.875rem;
        font-size: 0.76rem;
      }
    }

    .tecma-accordion-content-header {
      display: flex;
      align-items: center;
      &:hover {
        background-color: $background-color-2;
      }
      .tecma-button {
        &:focus {
          outline: none;
        }
      }
      .tecma-icon {
        transition: transform 0.5s ease-in-out;
      }
      &.open {
        .tecma-icon {
          transform: rotate(-180deg);
        }
      }
      &.disabled {
        cursor: default;
        pointer-events: none;
        background-color: $semantic-disabled-light;
      }
      &.clickable-header {
        cursor: pointer;
        &:active {
          background-color: $background-color-1;
        }
      }
      &.outlined {
        background-color: transparent;
        color: $primary;
        border: 0.063rem solid $primary;

        &:hover {
          color: $primary-light;
          border-color: $primary-light;
        }

        &:active {
          color: $primary-dark;
          outline-color: $primary-dark;
        }
      }
    }

    .tecma-accordion-content-panel {
      overflow: hidden;
      max-height: 0;
      overflow: hidden;

      transition: max-height 5s cubic-bezier(0, 1.05, 0, 1);
      &.open {
        transition: max-height 5s linear;
        max-height: 2000px;
      }
    }
  }
}
