@use "../../theme/utils/variables" as *;

.tecma-avatar {
  box-sizing: border-box;
  color: primary;
  background-color: $background-color-1;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  span {
    line-height: 0.75;
  }

  &.small {
    width: 1rem;
    height: 1rem;
    font-size: 0.7rem;
    .avatar-icon {
      padding: 0.2rem;
    }
  }

  &.medium {
    width: 1.5rem;
    height: 1.5rem;
    .avatar-icon {
      padding: 0.3rem;
    }
  }

  &.large {
    width: 2rem;
    height: 2rem;
    font-size: 1.2rem;
    .avatar-icon {
      padding: 0.4rem;
    }
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
