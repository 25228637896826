// CONTENT SPACER
$sm-space: 1rem;
$md-space: $sm-space*2;
$lg-space: $sm-space*3;

html{
    scroll-behavior: auto;
}
body{
    background: rgba($background-color--2, 0.6);
}

// MAIN CONTENT LAYOUT
%content-layout{
    width: 80%;
    margin: $sm-space auto;
    padding: $sm-space;
    box-sizing: border-box;
}

// ALIGN
// Position absolute - center
%center-absolute-X{
    left: 50%;
    transform: translateX(-50%);
}
%center-absolute-Y{
    top: 50%;
    transform: translateY(-50%);
}
%center-absolute-XY{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


// RESPONSIVE BREAKPOINTS
$small-desktop: 991px;
$tablet: 768px;
$mobile: 480px;


// RESPONSIVE VISIBILITY

@media screen and (max-width: 991px) {
    .md-hidden{
        display: none !important;
    }
}
@media screen and (max-width: 768px) {
    .sm-hidden{
        display: none !important;
    }
}
@media screen and (max-width: 480px) {
    .xs-hidden{
        display: none !important;
    }
}