.sidebar {
  position: fixed;
  flex-wrap: nowrap;
  height: 100%;
  right: 0;
  top: 0;
  display: block;
  z-index: 9;
}

.sidebar_column {
  height: 100%;
  position: fixed;
  right: 0px;
  @extend %center-absolute-Y;
  top: 50%;
  &.sidebar__sales-kit {
    background-color: $primary-color;
    display: inline-block;
    width: 80px;
    //border-left: 0.5rem solid $white;
    z-index: 10;
    .primary__logo--sidebar {
      position: absolute;
      @extend %center-absolute-XY;
    }
  }
  &.sidebar_pagination-nav {
    ul {
      list-style: none;
      position: relative;
      right: 80px;
      @extend %center-absolute-Y;
      li {
        margin: 0.5rem 1rem;
        a {
          color: $black;
          font-size: 1.3rem;
          font-weight: $bold;
          border-right: 3px solid rgba($black, 0.2);
          padding: 0.5rem 0.3rem;
          opacity: 0.4;
          width: 15px;
          display: inline-block;
        }
        &.current a {
          border-right: 3px solid $primary-color;
          opacity: 1;
        }
        &.disabled a {
          cursor: text;
          pointer-events: none;
        }
      }
    }
  }
}

.language {
  position: absolute;
  left: 50%;
  top: 90%;
  transform: translate(-50%, -90%);
}

.language-contrast-background {
  left: -1000%;
  top: 0;
  right: 0;
  bottom: 0;
  height: 200vh;
  width: 200vw;
  z-index: 100000;
  display: flex;
  position: fixed;
  background-color: rgba(black, 0.95);
  @extend %center-absolute-X;
}

.language-menulist {
  background-color: white;
  width: auto;
  height: auto;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 0px 0px 30px 5px rgba(black, 0.2);
  position: absolute;
  transform: translate(-130%, -50%);
  color: black;
  font-size: 0.9rem;
  text-align: left;
  text-transform: uppercase;
  font-weight: 300;
  min-height: 125px;
  min-width: 150px;
}

.quotation-exit.language-selected {
  color: white !important;
  width: initial;
  padding: inherit;
  position: initial;
  border-radius: 2px;
  box-sizing: border-box;
  font-size: initial;
}

.vendor-name.language-item {
  width: auto;
  overflow: hidden;
  font-size: 1rem;
  box-sizing: border-box;
  min-height: 48px;
  line-height: 1.5;
  padding-top: 6px;
  white-space: nowrap;
  letter-spacing: 0.00938em;
  padding-bottom: 6px;
  text-transform: capitalize;
  font-weight: 700;
}
.vendor-name.language-item:not(.language-selected) {
  font-weight: 400 !important;

  &:hover {
    background-color: inherit !important;
  }
}

// Buttons
.round-button {
  @extend %round-button;
}
