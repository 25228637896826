@mixin flexContainer($direction: null, $align-items: null, $align-content: null, $justify-content: null, $flex-wrap: null) {
  display: -webkit-flex;
  display: flex;
  flex-direction: $direction;
  -webkit-flex-direction: $direction;
  align-items: $align-items;
  align-content: $align-content;
  justify-content: $justify-content;
  flex-wrap: $flex-wrap;
}

@mixin flexChildren($flex-grow: null, $flex-shrink: null, $flex-basis: null, $align-self: null) {
  display: flex;
  flex-grow: $flex-grow;
  flex-shrink: $flex-shrink;
  flex-basis: $flex-basis;
  align-self: $align-self;
}

@mixin text($color: unset, $fontSize: 12px, $fontFamily: null, $fontWeight: 500, $lineHeight: null) {
  color: $color;
  font-size: $fontSize;
  font-weight: $fontWeight;
  font-family: $fontFamily;
  line-height: $lineHeight;
}
