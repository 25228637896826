// Base Font for HTML
$base-font-size: 16px;

// Paragraph Styles
$paragraph-color: $text-color;
$paragraph-size: 1.2rem;
$paragraph-line-height: 1.5;
$paragraph-margin: 20px;

// Heading Styles
$heading-color: $primary-color;
$heading-size: 2rem;
$heading-line-height: 1.25;
$heading-margin: $paragraph-margin * 1.2;

// Weight
$normal: 400;
$semibold: 500;
$bold: 700;

html,
body {
  font-size: $base-font-size;
  font-family: $primary-font;
  color: $text-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

// Heading
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: $heading-margin * 1.5;
  color: $heading-color;
  line-height: $heading-line-height;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }
}

h1 {
  font-size: $heading-size;
  margin-bottom: $heading-margin;
}
h2 {
  font-size: calc($heading-size / 1.3);
  margin-bottom: calc($heading-margin / 1.3);
}
h3 {
  font-size: calc($heading-size / 1.5);
  margin-bottom: calc($heading-margin / 1.5);
}
h4 {
  font-size: calc($heading-size / 1.7);
  margin-bottom: calc($heading-margin / 1.7);
}
h5 {
  font-size: calc($heading-size / 1.8);
  margin-bottom: calc($heading-margin / 1.8);
}
h6 {
  font-size: calc($heading-size / 2);
  margin-bottom: calc($heading-margin / 2);
}

// Paragraphs
p {
  margin-bottom: $paragraph-margin;
  font-size: $paragraph-size;
  line-height: $paragraph-line-height;
  color: $paragraph-color;

  &:last-of-type {
    margin-bottom: 0;
  }
}

// Links
a {
  color: $link-color;
  transition: all 0.2s ease;
  text-decoration: none;

  &:hover {
    color: darken($link-color, 20%);
  }
}

// Disclaimer
$disclaimer-font-size: 0.7rem;
$disclaimer-line-height: 1.5;
$disclaimer-link-size: 0.8rem;


// Misc Styles
em,
i,
.italic {
  font-style: italic;
}
strong, b, .bold {
  font-weight: 700;
}
.center-text{
  text-align: center;
}

