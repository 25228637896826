.virtual-photo-menu {
  .single-virtual-photo-images,
  .single-virtual-photo-template-images {
    margin-top: 20px;

    .draggable-list-elements {
      .main-content {
        background-color: white;

        &:nth-child(2n) {
          background-color: #f8f8f8;
        }
      }
    }

    .draggable-element,
    .draggable-element.draggable-element-placeholder {
      margin: 0px !important;
      border: none;
      background-color: white;
      padding-left: 0px !important;
      cursor: default;
      -webkit-user-select: unset;
      user-select: unset;
      align-items: stretch;
      position: relative;

      .element-content {
        margin-left: 0px;
      }

      .draggable-icon {
        align-items: center;
      }

      .virtual-photo-drag-content {
        display: flex;
        align-items: stretch;
        flex-grow: 1;
        height: 100%;

        .trash-container {
          display: flex;
          align-items: center;
        }

        .drag-index {
          width: 48px;
          min-height: 100%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          // background-color: white;
        }

        .checkbox-mosaic-container {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-left: 24px;
        }

        .virtual-photo-info-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-grow: 1;
          padding: 24px 32px;

          .virtual-photo-mosaic-container {
            > div:not(:last-child) {
              margin-bottom: 32px;
            }
          }

          .cms-file-input-right {
            align-self: flex-end;
          }
        }
      }
    }
  }

  .cms-text-input-container input {
    width: 400px;
  }
}
