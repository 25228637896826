$zero: 0px;
$presetBorder: 1px;
$presetMargin: 10px;
$presetPadding: 10px;
$heroMargin: 30px;
$breakpoint: 1200px;
$breakpoint2: 1600px;

.upselling-fixedBackground {
  height: 100vh;
  width: 100vw;
  max-height: auto;
  max-width: auto;
  min-height: auto;
  min-width: auto;
  position: fixed;
  background-color: white; //#f2f2f2
  opacity: 0.5;
}

.upselling-heroWrapper {
  height: 100vh;
  width: 100vw;
  max-height: auto;
  max-width: auto;
  min-height: auto;
  min-width: auto;
  display: flex;
  flex-direction: row;
  align-items: top;
  position: fixed;
  z-index: 1;
  background: #f2f2f2;
}

.upselling-heroChild {
  height: 95%;
  width: 93%;
  max-height: auto;
  max-width: auto;
  min-height: 650px;
  min-width: 1000px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-right: 105px;
  margin-bottom: 30px;
  margin-left: 30px;

  // @include padding($top: $presetPadding, $right: $presetPadding, $bottom: $presetPadding, $left: $presetPadding);
  &.left {
    height: 50vh;
    width: 68vw;
    max-height: auto;
    max-width: auto;
    min-height: 93%;
    min-width: 200px;
    position: fixed;
    top: 10;
    right: 20;
    bottom: 10;
    left: 10;
    flex-grow: 2;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    background-color: black;
    //background-image: url();
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }

  &.right {
    height: 100%;
    width: 20%;
    max-height: auto;
    max-width: auto;
    min-height: auto;
    min-width: 450px;
    flex-shrink: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    margin-right: -35px;
    margin-bottom: 0px;
    margin-left: 0px;
    border-radius: #242323;
    -webkit-border-radius: #242323;
    -moz-border-radius: #242323;
    -ms-border-radius: #242323;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    will-change: opacity;
    align-self: flex-end;
  }
}

.upselling-heroChildLeft_container {
  height: 60vh;
  width: 100%;
  min-width: 450px;
  position: relative;
}

.parquetImage {
  height: 40px;
  width: 40px;
  margin-right: 20px;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 100%;
  border-style: solid;
  border-color: #006b68;
  border-left-width: 30px;
  border-top-width: 30px;
  border-right-width: 30px;
  border-bottom-width: 30px;
}

.upselling-title {
  height: auto;
  width: 100%;
  max-height: auto;
  max-width: auto;
  min-height: auto;
  min-width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  &.upselling-navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

/*******navigation*******/

.upselling-navigation {
  height: auto;
  width: 100%;
  max-height: auto;
  max-width: auto;
  min-height: auto;
  min-width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upselling-navigationElementWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 90%;
  max-height: auto;
  max-width: auto;
  min-height: auto;
  min-width: auto;
  margin-bottom: -30px;
  padding-top: 10px;
  border-style: hidden;
  border-width: 1px;
  border-left-width: 0px;
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-color: white;
  overflow: visible;
}

.navigationElement {
  height: auto;
  width: 70px;
  max-height: auto;
  max-width: auto;
  min-height: auto;
  min-width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 5px;
  padding-left: 5px;
  cursor: pointer;
  overflow: visible;

  &.active {
    background-color: null;
    opacity: null;
  }
}

.navigationIcon {
  height: 45px;
  width: 45px;
  max-height: auto;
  max-width: auto;
  min-height: auto;
  min-width: auto;
  //background-image: url();
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 100%;
  border-style: solid;
  border-width: 2px;
  border-color: #b9a136;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  z-index: 50;

  &.active {
    background-color: #b9a136;
    border-radius: 100%;
    border-style: solid;
    border-width: 20px;
    border-color: #e2e2e2;
    -webkit-border-radius: 100%;
  }

  &.visited {
    background-color: #006b68;
  }
}

.navigationArrow {
  margin-bottom: 20px;
  height: 7px;
  width: 7px;
  max-height: auto;
  max-width: auto;
  min-height: auto;
  min-width: auto;
  background-image: url('/home-configurator/img/navigationArrow.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80%;
  margin-top: 20px;
  padding: 7px;
}

.textLine {
  height: 1px;
  width: 40px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #006b68;
  margin-top: 5px;
  margin-bottom: 5px;
  align-content: center;
}

.textLineOptions {
  height: 1px;
  width: 300px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: black;
  margin-top: 12px;
  margin-bottom: 12px;
  align-content: center;
}

.graphicConnector {
  height: 50px;
  width: 27px;
  max-height: auto;
  max-width: auto;
  min-height: auto;
  min-width: auto;
  margin-top: -10px;

  &.active {
    background-color: #e2e2e2;
  }
}

/*******options*******/

.options {
    height: 55%;
    width: 100%;
    max-height: 450px;
    max-width: auto;
    min-height: 300px;
    border-style: hidden;
    border-width: 1px;
    border-color: white;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: space-around;
    z-index: 2;
    background: #e2e2e2;
    margin-bottom: 20px;
    border-radius: inherit;
    box-shadow: 6px 8px 12px -2px grey;
}

.optionChoiceWrapper{
    //height: 320px;
    width: 90%;
    max-height: auto;
    max-width: auto;
    min-height: 150px;
    min-width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    transition: all 0.5s ease;
    
    &.wrapp{
        flex-wrap: wrap;
        padding-top: 25px;
    }    

    &.wrapp2{
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 200px 5em min-content auto;
        grid-column-gap: 10px;
        grid-row-gap: 10px;
    }
    
}

.optionElement:nth-child(-1n + 3) {
  grid-column: span 4;
}
.optionElement:nth-last-child(2) {
  grid-row-start: 2;
  grid-column: 3 / span 4;
}
.optionElement:nth-last-child(1) {
  grid-row-start: 2;
  grid-column: 7 / span 4;
}
.optionElement {
  background-color: invisible;
}

.optionElement {
  @extend .navigationElement;
  height: 130px;
  width: 140px;
  max-height: auto;
  max-width: auto;
  min-height: auto;
  min-width: auto;
  display: flex;
  align-items: center;
}

.optionIcon {
  height: 80px;
  width: 80px;
  max-height: auto;
  max-width: auto;
  min-height: auto;
  min-width: auto;
  // background-image: url();
  background-position: center;
  background-repeat: no-repeat;
  background-size: 120%;
  border-radius: 100%;
  border-style: solid;
  border-width: 3px;
  border-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: inset 8px 5px 8px 0px rgba(0, 0, 0, 0.2);
  transition: all 1s ease;

  &.active {
    height: 80px;
    width: 80px;
    max-height: 100%;
    max-width: 100%;
    min-height: auto;
    min-width: auto;
    margin-top: -5px;
    border-style: solid;
    border-width: 7px;
    border-color: #b9a136;
    transition: all 1s ease;
  }
  &:hover {
    @extend .active;
  }
}

.border {
  border-radius: 100%;
  border-style: dotted;
  border-width: 0px;
  border-color: white;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  transition: all 1s ease;

  &.active {
    border-style: hidden;
    border-width: 2px;
    border-color: #c6d76b;
    transition: all 1s ease;
  }
  &:hover {
    @extend .active;
  }
}

.lineWrapper {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.line {
  width: 40px;
  height: 1px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.navbarUpselling {
  background-color: #16373c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  width: 70px;
  max-height: 100vh;
  max-width: auto;
  min-height: auto;
  min-width: auto;
  position: fixed;
  right: 0%;
  border-style: solid;
  border-left-width: 10px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-color: white;
  z-index: 500;
}

.icoLogo {
  height: 85px;
  width: 168px;
  max-height: auto;
  max-width: auto;
  min-height: auto;
  min-width: auto;
  margin-top: 5px;
  align-content: center;
  background-repeat: no-repeat;
}
.btnAmbinte {
  font-weight: 500;
  font-size: 12px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  width: 100px;
  height: 40px;
  color: white;
  text-transform: uppercase;
  z-index: 2000;
  background-color: #16373c;
  padding: 5px;
  border-radius: 0;
  border-color: #b9a136;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.5s ease;

  &.active {
    background-color: #b9a136;
  }
}

.boldUpselling {
    text-align: center;
    color: black;
    font-weight: 500;
    font-size: 13px;
    margin-bottom: unset;
    margin-top: 20px;
}

.icoBack {
  background-image: url('/home-configurator/img/ico_back.svg');
  border-radius: 50%;
  border-style: solid;
  border-width: 3px;
  border-color: #ffffff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  height: 40px;
  width: 40px;
  max-height: auto;
  max-width: auto;
  min-height: auto;
  min-width: auto;
  margin-top: 50px;
  margin-bottom: 20px;
}

.price {
  text-align: center;
  color: black;
  font-weight: 500;
  font-size: 12px;
}

.thinOptions {
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.thin {
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.activelighter {
    font-size: 11px;
    text-transform: uppercase;
    text-align: center;
    color: #bababa;
}

.false {
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
}
.navigationNumber {
  font-size: 17px;
  text-align: center;
  text-transform: uppercase;
}

.riepilogo {
  height: 40px;
  width: 130px;
  font-weight: 500;
  font-size: 14px;
  color: white;
  position: absolute;
  text-transform: uppercase;
  top: 16px;
  left: 50px;
  z-index: 2000;
  background-color: #16373c;
  padding: 5px;
  border-radius: 0;
  border-width: 10px;
  border-color: #16373c;
  cursor: pointer;
  -webkit-box-shadow: 10px 10px 18px -4px rgba(0, 0, 0, 0.51);
  box-shadow: 10px 10px 18px -4px rgba(0, 0, 0, 0.51);
  text-decoration: none;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  text-align: center !important;
  // margin: 0 auto !important;
  // display: inline-block;
  // border-top-width: 0px;
  // border-bottom-width: 0px;
  // border-right-width: 0px;
  // border-left-width: 0px;
}

.btnAmbienteCapitolato {
  font-weight: 500;
  font-size: 12px;
  width: 450px;
  height: 40px;
  align-content: center;
  color: black;
  text-transform: uppercase;
  z-index: 2000;
  background-color: #b9a136;
  //padding: 5px;
  border-radius: 0;
  border-color: #c6d76b;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.5s ease;

  &.active {
    background-color: #c6d76b;
  }
}
