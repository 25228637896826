@import '../../../scss/common/mixin.scss';

.hover-menu {
  position: fixed;
  z-index: 4;

  .icon-modify {
    cursor: pointer;
    border: 2px solid #6bc1b4;
    border-radius: 50%;
    background-color: white;
  }
}

.edit-css-modal {
  font-family: 'SegoeRegular';

  .edit-css-modal-title {
    margin-top: 20px;

    @include text($color: black, $fontWeight: 700, $fontSize: 25px);
  }

  .action-button-container {
    display: flex;
    padding: 10px;
    justify-content: flex-end;

    > * {
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }
}
.edit-css-modal-body {
  margin-top: 5px;

  .single-css-property {
    display: flex;
    padding: 0px 10px;
    padding: 10px 0px;
    padding: 20px 10px;

    .cms-custom-select {
      background-color: transparent;
    }

    &:nth-child(odd) {
      background-color: #f4f4f5;
    }

    .css-property-name {
      display: flex;
      flex-grow: 1;
      padding-top: 5px;
    }

    .css-values {
      display: flex;

      .cms-custom-select-container {
        margin-top: 0px;
      }

      &.single-row-css-values {
        display: flex;
        flex-direction: column;

        .single-row-css-value {
          display: flex;
          justify-content: flex-end;

          &:not(:first-child) {
            margin-top: 10px;
          }
        }
      }

      .single-css-value {
        display: inline-flex;

        &:not(:first-child) {
          margin-left: 30px;
        }

        // Dimensione input
        .css-number-input-title {
          font-size: 12px;
          margin-top: 5px;
        }

        .css-number-input {
          display: flex;

          // Nascondo frecce su input number
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          .css-number-input-with-title {
            display: flex;
            flex-direction: column;
          }

          input[type='number'] {
            width: 50px;
            height: 25px;
            border: 1px solid black;
            border-radius: 2px;
            text-align: center;
          }

          .cms-select-container {
            width: 50px;
            height: 25px;
            margin-left: 5px;

            .cms-custom-select-container {
              min-width: unset;
              margin-top: 0px;
            }

            .cms-custom-select {
              padding: 5px 20px;
            }
          }
        }
      }

      // Integer input
      .css-number-integer-input {
        display: inline-flex;

        input {
          width: 50px;
          height: 25px;
          border: 1px solid black;
          border-radius: 2px;
          text-align: center;
        }
      }
    }
  }
}
.css-custom-menu-template {
  .css-custom-menu-desc {
    margin-bottom: 10px;
    margin-top: 10px;
    display: block;
    text-align: left;
    padding-left: 10px;
    font-style: italic;
  }
}

.css-highlight-single-element {
  border: 2px solid red;
  position: fixed;
}

.single-css-property {
  // Input grid area
  &.grid-area-property {
    .css-values {
      padding-top: 5px;
      flex-grow: 1;
      align-items: flex-start;

      .single-row-css-value {
        width: 100%;

        .css-number-integer-input-wrapper {
          width: 100%;
        }

        .css-number-integer-input {
          width: 100%;
          justify-content: space-between;
        }
      }
    }
  }
}
