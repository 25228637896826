header .client-vendor-names {
  position: absolute;
  top: 2%;
  right: 0%;
  display: flex;
  flex-direction: row;
  transform: translateX(calc(-90px));
  > span:nth-child(2) {
    margin-left: 10px;
  }
  .vendor-name,
  .client-name {
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    font-family: neue-haas-grotesk-display, sans-serif;
    &.role {
      font-weight: 700;
    }
  }
}

.quotation-exit.draftquote {
  padding: 1rem;
  width: 450px;
  box-sizing: border-box;
  border-radius: 0px;
  box-shadow: 3px 3px 3px rgba(black, 0.2);
  top: 20%;
  left: 50%;
  transform: translateX(-50%);

  .dialogue-draftquote h4 {
    color: white;
    font-size: 0.9rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
  }
}

// Language Switch FPL Web
header {
  position: relative;
  z-index: 2;

  .primary__logo--header {
    @media screen and (max-width: $mobile) {
      margin-top: 3rem !important;
    }
  }

  .language-switch-container {
    position: absolute;
    right: 50px;
    top: 50%;
    @media screen and (max-width: $mobile) {
      top: -25% !important;
    }
    @media screen and (max-width: 992px) {
      right: 20px;
      top: -9%;
    }
    transform: translateY(-50%);
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    > * {
      display: inline-flex;

      &.divider {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}
