// BUTTON CONFRONTA
.round-button--confronta {
  @include icon(contain, no-repeat, 100%, 60px, 90px);
  border-radius: 0 !important;
  &:after {
    position: absolute;
    bottom: -10px;
    left: -5px;
  }
}

.plan-list {
  min-height: 60vh;
  .plan-wrapper {
    background-color: #f9f3ee;
    position: relative;
    margin: $lg-space auto;
    padding: $sm-space;
    box-sizing: border-box;
    display: flex;
    width: 80%;
    box-shadow: 2px -2px 20px 0px rgba($black, 0.2);
    + .plan-wrapper {
      margin-top: $lg-space * 2;
    }
    &.standard-plan-sup {
      .services {
        flex-wrap: wrap;
      }
      .plan-image {
        background-position: center;
        height: unset;
      }
    }
    &.hide {
      display: none;
    }
  }
}

// Image side
.plan-wrapper .plan-image {
  flex-basis: 40%;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center 0;
  background-color: $white;
  height: 400px;
  min-width: 400px;
  position: relative;
  box-shadow: 5px 5px 10px -2px rgba(0, 0, 0, 0.2);
  //overflow: hidden;
  z-index: 2;
}
.plan-image__button-container {
  display: block;
  width: 107%;
  height: 70px;
  position: absolute;
  left: -33px;
  bottom: -30px;
  text-align: center;
  .plan-image__button {
    background-color: $white;
    margin: 10px 0 0 10px;
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;
  }
}

// Info general style
.plan-info {
  flex-basis: 60%;
  justify-content: center;
  display: flex;
  position: relative;
}
.plan-info:after {
  content: '▲';
  color: #fff;
  font-size: 4rem;
  line-height: 0;
  display: block;
  left: -16px;
  transform: rotate(90deg) scaleX(3.5);
  z-index: 2;
  text-shadow: 0px -7px 4px rgba(0, 0, 0, 0.2);
  position: absolute;
  @extend %center-absolute-Y;
}

// description side

.plan-info__description__wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1rem 0 4rem;
  flex-direction: row-reverse;
}
.plan-info__description__wrapper > div {
  flex: 0 0 100%;
}
.plan-info__description__wrapper--top {
  display: flex;
}
.plan-info__description__wrapper--top .plan-info__title {
  background-color: $tertiary-color;
  color: $white;
  text-align: center;
  height: auto;
  margin: -2rem auto 2rem auto;
  padding: 0 4rem 1rem 4rem;
}
.plan-info__description__wrapper--top .plan-info__title h1 {
  color: $primary-color;
  font-family: $secondary-font;
  font-size: 3rem;
  font-weight: normal;
  display: inline-block;
  margin: 0 0 0 0.5rem;
  padding: 0;
}
.plan-info__description__wrapper--top .plan-info__title h5 {
  color: $primary-color;
  text-transform: uppercase;
  font-weight: normal;
  line-height: 1;
  padding: 1rem 0 0 0;
  display: inline-block;
}
.plan-info__description__wrapper--top .price {
  font-family: $secondary-font;
  font-weight: bold;
  text-align: center;
  font-size: 1.1rem;
  margin: 1rem 0 0 0;
  padding: 0.5rem 0;
  border: 3px solid $success-color;
}
.plan-info__description__wrapper--top .right-block {
  padding: 0 0 0 10%;
}
.plan-info__description__wrapper--top .right-block .description {
  display: flex;
  flex-wrap: nowrap;
}
.plan-info__description__wrapper--top .right-block h6 {
  font-family: $secondary-font;
  font-size: 0.8rem;
  text-transform: uppercase;
}
.plan-info__description__wrapper--top .right-block h6:before {
  content: '';
  background-color: $primary-color;
  height: 3px;
  width: 25px;
  margin: 0 1rem 0 0;
  display: inline-block;
  position: relative;
  top: -5px;
}
.plan-info__description__wrapper--top .right-block .description h2 {
  font-family: $secondary-font;
  font-size: 2.5rem;
  line-height: 0.9;
  font-style: italic;
  font-weight: normal;
}
.plan-info__description__wrapper--top .right-block .description p {
  font-family: $secondary-font;
  font-style: italic;
  letter-spacing: -0.02em;
  font-size: 1rem;
  padding: 0.5rem 0 0 2rem;
  margin: 0 0 0 1rem;
  border-left: 1px solid $primary-color;
}

// center
.services {
  display: flex;
  text-align: center;
  justify-content: space-between;
}
.services #ico-piano span {
  width: initial;
  display: inline-block;
}
.services #ico-piano span:not(:last-of-type):after {
  content: '-';
  margin: 0 0.2rem;
}

// bottom
.plan-info__description__wrapper--bottom {
  margin: 1rem 0 0 0;
  text-align: right;
}
.plan-info__description.plan-info__description--left .secondary-button {
  background: none;
  width: 100%;
  margin: 0 0 0.5rem 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.plan-info__description--left .box__description--bottom {
  position: absolute;
  bottom: 1rem;
  @extend %center-absolute-X;
}
.plan-info__description__wrapper > div .start-price {
  text-align: center;
  margin: 0rem 0 2rem 0;
  padding: 1rem 0 0 0;
}
.plan-info__description__wrapper > div .start-price:before {
  content: '';
  background-color: $success-color;
  margin: 0rem auto 0.7rem auto;
  height: 2px;
  width: 100px;
  display: block;
}

// STATUS LOCK
.plan-list .plan-wrapper {
  &.plan-status__lock {
    .button {
      opacity: 0.5;
      pointer-events: none;
    }
    .plan-image__data.plan-image__data--views {
      display: none;
    }
  }
}

[class*='plan-status--'] {
  .plan-image {
    .availability-label {
      content: '';
      background: $primary-button-color;
      text-transform: uppercase;
      color: $white;
      font-size: 1.3125rem;
      padding: 0.625rem;
      position: absolute;
      top: 1rem;
    }
  }
}

.configurator-boxes {
  .box {
    .quote-number-container {
      margin-left: unset !important;
    }
  }
}

@media screen and (max-width: 991px) {
  .configurator-boxes {
    .box {
      .quote-number-container {
        bottom: 20px;
      }
    }
  }

  .quote-number-container-model {
    top: unset !important;
  }

  .footer__links .listPolicies {
    margin-left: 20px;
    margin-right: 20px;
    padding: 0;
  }
}

@media screen and (max-width: 1300px) {
  .plan-list .plan-wrapper .plan-image {
    height: 300px !important;
  }
}

.quote-number-container {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: auto;
  right: auto;
  bottom: -25px;
  display: flex;
  width: 300px;
  height: 45px;
  justify-content: flex-start;
  align-items: center;
  border-style: solid;
  border-width: 4px;
  border-color: #fff;
  border-radius: 150px;
  background-color: #e22626;
  z-index: 3;
  .quote-number-container-exclaimer {
    position: relative;
    left: -4px;
    display: flex;
    width: 45px;
    height: 45px;
    min-width: 45px;
    min-height: 45px;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 4px;
    border-color: #fff;
    border-radius: 150px;
    color: #fff;
    font-size: 30px;
    font-weight: 900;
  }
  @media screen and (max-width: $mobile) {
    border-width: 2px;
    max-width: 90%;
    height: 1.875rem;
    .quote-number-container-exclaimer {
      font-size: 1.25rem;
      height: 1.875rem;
      width: 1.875rem;
      min-width: 1.875rem;
      min-height: 1.875rem;
      border-width: 2px;
      left: -2px;
    }
    .quote-number-container-text {
      font-size: 12px !important;
      width: 100%;
      margin: 0 !important;
      padding: 0 1rem;
    }
  }
  .quote-number-container-text {
    margin-left: 10px;
    color: #fff;
    font-size: 14px;
    line-height: 13px;
    padding-right: 10px;
  }
}

.quote-number-container-model {
  position: relative;
  left: 0px;
  top: -90px;
  // right: 0;
  // bottom: -25px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 280px;
  height: 45px;
  justify-content: flex-start;
  align-items: center;
  border-style: solid;
  border-width: 4px;
  border-color: #fff;
  border-radius: 150px;
  background-color: #e22626;
  z-index: 3;
  .quote-number-container-exclaimer {
    position: relative;
    left: -4px;
    display: flex;
    width: 45px;
    height: 45px;
    min-width: 45px;
    min-height: 45px;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 4px;
    border-color: #fff;
    border-radius: 150px;
    color: #fff;
    font-size: 30px;
    font-weight: 900;
  }
  .quote-number-container-text {
    margin-left: 10px;
    color: #fff;
    font-size: 14px;
    line-height: 13px;
    padding-right: 10px;
  }
}

.messaggio_form {
  color: #955550;
  font-weight: bold;
}
.modal__zoom-plan .modal__button.modal-close {
  filter: invert(100%) !important;
}
.modal__button.modal-close {
  filter: invert(100%) !important;
}
.plan-info__description__wrapper {
  padding: 1rem 0;
}

.error-form {
  outline: 1px solid red !important;
}
.w-checkbox-input.error-form {
  height: unset !important;
  margin-top: 12px;
}
.error-form-message {
  color: red;
  font-size: 12px;
  margin: 0 !important;
}

@media screen and (max-width: 1600px) {
  .plan-info__description__wrapper--top .right-block .description {
    flex-wrap: wrap;
  }
  .plan-wrapper .plan-image {
    background-position: center;
  }
  .plan-info__description__wrapper--top .right-block .description > * {
    flex: 0 0 100%;
  }
  .plan-info__description__wrapper--top .right-block .description h2 {
    font-size: 2.5rem;
  }
  .plan-info__description__wrapper--top .right-block .description br {
    display: none;
  }
  .plan-info__description__wrapper--top .right-block .description p {
    border: none;
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 1300px) {
  .plan-list .plan-wrapper {
    flex-wrap: wrap;
  }
  .plan-list .plan-wrapper .plan-image,
  .plan-list .plan-wrapper .plan-info {
    flex: 0 0 100%;
  }
  .plan-wrapper .plan-image {
    background-size: contain;
  }
  .plan-list .plan-wrapper .plan-info::after {
    font-size: 2rem;
    top: 5px;
    left: calc(50% - 4rem);
    transform: rotate(180deg) scaleX(3.5) translateX(-50%);
    transform-origin: 1rem 0;
  }
  .plan-info__description__wrapper--top .plan-info__title {
    padding: 2rem 4rem 1rem 4rem;
  }
  .plan-info__description__wrapper {
    padding: 1rem 0 0 0;
  }
  .plan-info__description__wrapper--top .right-block {
    padding: 3rem 0 0 10%;
  }
  .plan-info__description__wrapper--middle .services {
    margin: 0.5rem 0;
  }
  .plan-list .plan-image__button-container {
    width: 15%;
    left: 91%;
    bottom: 60%;
  }
}

@media screen and (max-width: $small-desktop) {
  .plan-list .plan-wrapper {
    width: 95%;
  }
  .plan-info__description__wrapper {
    padding: 1rem 0 !important;
  }
}

@media screen and (max-width: $tablet) {
  .plan-info__description__wrapper--top {
    flex-wrap: wrap;
  }
  .plan-info__description__wrapper--top .left-block {
    width: 100%;
  }
  .plan-info__title > * {
    width: inherit;
    display: inline-block;
  }
  .plan-info__description__wrapper--top .right-block {
    padding: 2rem 0 0 0;
  }
  .plan-wrapper .plan-image {
    min-width: inherit;
  }
  .plan-info__description__wrapper--middle .services {
    flex-wrap: wrap;
    margin: 1rem 0;
  }
  .plan-info__description__wrapper--middle .services > div {
    flex: 1 0 33%;
    margin: 0.5rem 0;
  }
  .plan-info__description__wrapper--middle .services > div:nth-child(3n) {
    border: none;
  }
  .plan-list .plan-wrapper .plan-image {
    height: 300px;
  }
  .plan-info__description__wrapper--top .right-block .description h2 {
    font-size: 2rem;
  }
  .plan-info__description__wrapper--middle .services > div b {
    margin: 0.7rem auto;
  }
  .plan-info__description__wrapper--top .plan-info__title {
    margin-bottom: 0.5rem;
  }
  .plan-info__description__wrapper--middle .services > div:before {
    margin: 0 auto;
  }
}

@media screen and (max-width: $mobile) {
  .plan-list .plan-image__button-container {
    left: auto;
    right: 0.625rem;
    top: 0.5rem;
  }
  .plan-info__description__wrapper--middle .services > div:before {
    width: 40px;
    height: 40px;
  }
  .plan-info__description__wrapper--middle .services > div {
    flex: 1 0 50%;
  }
  .plan-info__description__wrapper--middle .services > div b {
    max-width: 100% !important;
  }
  .plan-info__description__wrapper--top .plan-info__title {
    padding-top: 3rem;
  }
  .plan-info__description__wrapper--top .plan-info__title h1 {
    font-size: 2.5rem;
  }
  .plan-info__description__wrapper--middle .services > div:nth-child(4n) {
    border: none;
  }
  .plan-info__description__wrapper--middle .services > div:nth-child(3n) {
    border-right: 1px solid #c7c7c7;
  }
  .plan-info__description__wrapper {
    padding: 0 !important;
    .contact-button {
      margin: auto;
      display: flex;
      flex-direction: column;
      left: auto !important;
      padding: 1rem;
    }
  }
  .plan-info__title {
    height: auto !important;
    padding: 2rem 1rem !important;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem 0;
    margin: 0 0 1rem 0 !important;
    h5,
    h1 {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  .modal__zoom-plan {
    .plan-info__title {
      gap: 1rem 0;
      padding: 1rem !important;
    }
  }
}

.modal__zoom-plan .modal__button.modal-close {
  filter: invert(100%) !important;
}

.modal__button.modal-close {
  filter: invert(100%) !important;
}

.plan-list .quote-number-container {
  left: 40% !important;
  bottom: 25px;
}

.plan-info__title {
  min-height: 60px;
}

.plan-list {
  #ico-piano {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      max-width: 100px;
    }
  }
}

// TABLET and less
@media screen and (max-width: 1300px) {
  .quote-number-container {
    margin-left: 100px;
  }

  .plan-list .quote-number-container {
    margin-left: 0px;
    bottom: 17% !important;
    left: 50% !important;
    transform: translateX(-50%);
    position: absolute !important;
  }

  // .plan-info__description__wrapper--middle {
  //  // margin-bottom: 80px;
  // }

  .plan-wrapper.plan-status--venduto {
    .quote-number-container {
      bottom: 12% !important;
      left: 50% !important;
      transform: translateX(-50%);
      position: absolute !important;
    }
  }
}

@media screen and (max-width: 991px) {
  .quote-number-container {
    margin-left: 100px;
  }
  .plan-list .quote-number-container {
    margin-left: 0px;
    bottom: 19% !important;
    left: 50% !important;
    transform: translateX(-50%);
    position: absolute !important;
  }
}

// Fix rotation modale zoom
.modal.modal__zoom-plan.full-page {
  .rotate {
    transform: rotate(180deg);
  }
}

.plan-postilla {
  text-align: 'left';
}

// @media screen and (max-width: 1300px){  //Fix red button
//   .plan-info__description__wrapper--middle {
//       margin-bottom: 150px;
//   }
// }

@media screen and (max-width: 768px) {
  .modal.modal__zoom-plan {
    .modal__content {
      background-image: unset !important;
    }
    .content__image {
      right: unset !important;
      background-size: contain !important;
    }
  }
}
