// MODAL
.modal {
  background-color: rgba($black, 0.85);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;

  .modal__wrapper {
    background: $white;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal__button {
      @extend %background-image;
      width: 20px;
      height: 20px;
      position: absolute;
      cursor: pointer;
      top: 2rem;
      transition: opacity 0.3s;
      z-index: 99;
      &:hover {
        opacity: 0.5;
      }
      &.modal-close {
        right: 2rem;
        z-index: 999;
      }
      &.modal-edit {
        right: 5rem;
      }
    }
    .modal__content {
      padding: $sm-space;
      @media screen and (max-width: $mobile) {
        padding: 0 !important;

        .content__image {
          width: 100%;
          background-size: 90% !important;
        }
        .plan-list {
          top: auto !important;
          left: auto !important;
          bottom: 0;
          width: 100%;

          .plan-info__title {
            margin: 0 !important;
            padding: 1rem 0 !important;
            width: 100%;
            h5,
            h1 {
              font-size: 1.25rem;
              margin: 0;
              padding: 0;
            }
            h5 {
              font-weight: 300;
            }
            h1 {
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  &:not(.full-page) {
    .modal__wrapper {
      width: 800px;
      height: 500px;
      max-width: 90%;
      max-height: 90%;
      @extend %center-absolute-XY;
    }
  }
  // full-page version
  &.full-page {
    .modal__wrapper {
      width: 100%;
      height: 100%;
      max-width: 100vw;
      max-height: 100vh;
      left: 0;
      top: 0;
    }
  }
}

// fix modal confronta

.modal__button.button-edit.button-edit--clear:nth-child(3) {
  right: 3rem !important;
}

.modal-close {
  @include icon(50%, no-repeat, center, 20px, 20px);
  right: 1rem;
  position: absolute;
  cursor: pointer;
}

.box-close {
  @include icon(50%, no-repeat, center, 20px, 20px);
  background-size: 50% !important;
  background-color: $primary-color;
  padding: 0.3rem;
  border-radius: 50%;
  right: 1rem;
  position: absolute;
  top: 1rem !important;
  z-index: 999 !important;
  cursor: pointer;
}

// Modal Zoom Plan

.modal__zoom-plan {
  .modal__content {
    @extend %background-image;
    background-size: contain;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    touch-action: none !important;
    overflow: hidden !important;
    position: fixed;
    left: 0;
    top: 0;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .content__image {
      @extend %background-image;
      height: 600px;
      width: 90%;
      right: -10%;
      position: relative;
    }
    .plan-list {
      min-height: inherit;
      position: absolute;
      bottom: 4%;
      left: 6%;
      .plan-info__description {
        width: 100%;
        h5 {
          color: $text-color !important;
          margin-right: 0.5rem;
        }
        h1 {
          margin-top: 0;
          color: $tertiary-color;
        }
      }
    }
  }
}

// Modal Vista

.modal__vista {
  .modal__wrapper {
    .modal__content {
      @extend %background-image;
      width: 80%;
      height: 80%;
    }
  }
}

// Modal FLoor

.modal__floor {
  .modal__wrapper {
    display: block;
    flex-wrap: wrap;
    padding: 3rem 2rem;
    box-sizing: border-box;
  }
  h2 {
    font-size: 1.3rem;
    font-weight: 300;
    padding: 0 0 2.5rem 0;
    margin: 0;
    flex: 0 0 100%;
  }
  .apartment-list {
    width: 100%;
  }
  .apartment-row {
    height: 60px;
    flex: 0 0 100%;
    display: flex;
    border-bottom: 0px solid $white;
    &:not(.header) {
      background-color: $tertiary-color;
      border-bottom: 5px solid $white;
      &:nth-child(2n) {
        background-color: darken($tertiary-color, 8%);
      }
    }
  }
  .apartment-row div {
    text-align: center;
    font-weight: bold;
    padding: 1.5rem 0;
    flex: 1 0 25%;
    align-items: center;
    box-sizing: border-box;
    border-right: 1px solid $white;
  }
  .apartment-row.header div {
    background-color: lighten($tertiary-color, 15%);
    color: $primary-color;
    font-size: 0.8rem;
    text-transform: uppercase;
    text-align: left;
    height: 100%;
    padding: 0 1rem 0 4rem;
    display: flex;
    position: relative;
  }
  .apartment-row.header div.scegli {
    background-color: transparent;
  }
  .apartment-row.header div:before {
    content: '';
    border: 1px solid $primary-color;
    border-radius: 50%;
    margin: 0.5rem;
    position: absolute;
    top: 0px;
    left: 5px;
  }
  .apartment-row div.nome:before {
    @include icon(70%, no-repeat, center, 40px, 40px);
  }
  .apartment-row div.piano:before {
    @include icon(70%, no-repeat, center, 40px, 40px);
  }
  .apartment-row div.prezzo:before {
    @include icon(70%, no-repeat, center, 40px, 40px);
  }
  .apartment-row div.scegli:before {
    content: none;
  }
  .apartment-row .primary-button {
    font-size: 0.85rem;
    padding: 1rem 2rem;
    &:hover {
      box-shadow: inset 0 0 0 2px $primary-color;
    }
  }
}

// NO TAP - Screen blocker

.notap.allblack {
  left: -1000%;
  top: 0;
  right: 0;
  bottom: 0;
  height: 200vh;
  width: 200vw;
  z-index: 100000;
  display: flex;
  position: fixed;
  background-color: rgba(black, 0.95);
  @extend %center-absolute-X;
}

.centered-modal {
  .modal__wrapper {
    height: auto !important;
  }

  .modal__button.modal-close {
    filter: invert(100%) !important;
  }

  .plan-info__title-modal {
    text-align: left !important;

    h3 {
      margin-top: 40px !important;
      font-weight: bold;
      font-size: 28px;
    }
  }

  h1 {
    margin-top: 30px;
    margin-bottom: 100px;
    font-size: 40px;
  }

  .modal__content {
    padding: 1.5rem !important;
    font-size: 12px;
  }

  .plan-list {
    min-height: unset !important;
  }

  .price-rata {
    font-family: europa, sans-serif;
    color: #000;
  }
}

.rate-modal {
  .modal__wrapper {
    height: auto !important;
  }

  .modal__button.modal-close {
    filter: invert(100%) !important;
  }
}

.modal__zoom-plan {
  .modal__button.modal-close {
    filter: invert(100%) !important;
  }
}

.modal__vista {
  .modal__button.modal-close {
    filter: invert(100%) !important;
  }
}

// modal alert
.modal-wrapper-alert {
  background-color: $primary-color !important;
}
.alert-modal-title {
  color: $alert-color;
  text-align: center;
}
.alert-modal-description {
  color: $tertiary-color;
  text-align: left;
}
