.outline-button {
  display: inline-flex;
  align-items: center;
  text-align: center;
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #018276;
  border: 1px solid #018276;
  border-radius: 2px;
  padding: 0 16px;
  cursor: pointer;
  transition: all 200ms;
  height: 32px;

  &.white {
    color: #fff;
    border-color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
  }
  &.square {
    width: 32px;
    justify-content: center;
    padding: 0;
  }

  &:hover {
    background-color: #b7b7b7a1;
  }
  &-icon.icon {
    height: 12px;
    width: 12px;
    padding: 0;
    background-size: contain;
  }

  & > *:nth-child(2) {
    margin-left: 8px;
  }
}
