// DISABLED BUTTON
$btn-disabled-opacity: 0.4;

// GENERIC BUTTON
.button {
  display: inline-block;
  font-family: $primary-font;
  font-weight: $normal;
  text-align: center;
  vertical-align: middle;
  border: 0 solid transparent;
  text-decoration: none;
  cursor: pointer;
  &:focus,
  &.focus {
    outline: 0;
  }
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
  }
}

// OFFCANVAS CART BUTTON
.button--cart {
  padding: $sm-space $sm-space;
  font-size: 1rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0 0 $sm-space 0;
  position: relative;
  overflow: hidden;
  span {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: relative;
    transition: all 0.3s;
    backface-visibility: hidden;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: -100%;
    opacity: 0.3;
    transition: all 0.3s;
  }
  &:not(.disabled):hover span {
    transform: translateY(300%);
  }
  &:not(.disabled):hover:before {
    top: 0;
  }
}

// SIDEBAR ROUND BUTTON
%round-button {
  @include icon(120%, no-repeat, center, 45px, 45px);
  display: block;
  margin: 8px auto;
  border-radius: 50%;
  box-sizing: border-box;
}

// BUTTON DESK RIEPILOGO
.button__desk {
  //transform: rotate(180deg);
  background: none;
  position: fixed;
  left: 83%;
  top: 5%;
  z-index: 8;
  &.button__desk--tailor {
    top: 10%;
  }
}

// BUTTON
.round-button--corner-tr {
  position: fixed;
  margin: 0;
  right: 7%;
  top: 0;
  border: none;
  border-radius: 0;
  z-index: 8;
  &:hover {
    opacity: 0.8;
  }
}

.round-button-menu {
  & svg path {
    fill: #fff;
  }
}

// BUTTON SIZES
.button-lg {
  padding: $sm-space $md-space;
  font-size: calc($heading-size/2);
}

.contact-button {
  font-weight: bold;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .contact-button {
    margin-right: 26%;
    margin-left: 25%;
  }
}
@media screen and (min-width: 768px) {
  .contact-button {
    margin-right: 37%;
    margin-left: 37%;
  }
}

@media screen and (min-width: 1023px) {
  .contact-button {
    margin-right: 26%;
    margin-left: 25%;
  }
}

@media screen and (min-width: 1024px) {
  .contact-button {
    margin-right: 37%;
    margin-left: 37%;
  }
}

@media screen and (min-width: 1300px) {
  .contact-button {
    margin-right: unset;
    margin-left: unset;
  }
}

// @media screen and (max-width: 540px){
//   .contact-button{
//     margin-right: 38%;
//     margin-left: 38%;
//   }
// }
