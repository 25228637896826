@import './../../common/mixin.scss';

.cms-top-bar {
  border-bottom: 1px solid #d3d3d3;
  z-index: 1301 !important;

  .outline-button:hover {
    background-color: #fff;
  }

  .cms-first-top-bar {
    height: 72px;
    background-color: #1e201f;
    color: white;
    display: flex;
    width: 100%;

    > div {
      display: inline-flex;
    }
    .toolsRedirector-icon {
      margin-left: 36px;
    }

    .tecma-logo-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 26px;
    }

    .emotions-name-container {
      display: flex;
      align-items: center;
      @include text($fontSize: 16px, $fontWeight: 600, $lineHeight: 20px);
    }

    .emotions-open-configurator {
      flex-grow: 1;
      display: flex;
      align-items: center;
      margin-left: 40px;
      @include text($fontSize: 16px, $fontWeight: 600, $lineHeight: 20px);
    }

    .admin-container {
      display: flex;
      align-items: center;
      padding: 0px 26px;
      &.close-menu {
        cursor: pointer;
      }
      .admin-name-container {
        margin-left: 10px;
        display: flex;
        align-items: center;
        @include text($fontSize: 16px, $fontWeight: 600, $lineHeight: 20px);
      }
      .admin-dropdown-content {
        display: none;
        position: absolute;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1301;
        margin-top: 109px;
        width: 100%;
        height: 55px;
        background-color: #e8e9eb;
        span {
          color: black;
          padding: 16px 20px;
          text-decoration: none;
          display: block;
          cursor: pointer;
          &:hover {
            background-color: #cacbd3;
          }
        }
        &.open-menu {
          display: block;
        }
      }
    }
    .controller-section {
      @include flexContainer($justify-content: space-between, $align-items: center);

      .button-modify {
        padding: 11px 16px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
      }
      .button-save {
        padding: 11px 16px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        height: auto;
      }

      .modify-cards {
        .link-cms {
          border-right: 1px solid white;
          border-bottom: 2px solid transparent;
          padding: 0 24px;
          height: 25px;
          p {
            font-size: 14px;
            line-height: 18px;
            color: #6bc1b4;
            font-weight: 700;
            border-bottom: 2px solid transparent;
            &:hover {
              border-bottom: 2px solid #6bc1b4;
            }
          }
        }
      }

      .modify-page {
        margin-left: 24px;
      }

      .save-configuration {
        margin-left: 16px;
      }

      .settings-open-container {
        margin-left: 24px;

        .settings-white {
          border-left: 1px solid white;
          cursor: pointer;
          padding: 0px 26px;
          border-right: 1px solid white;
        }
      }
    }
  }
}
