@use "../../theme/utils/variables" as *;

.tecma-button {
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: $primary;
  color: white;
  box-sizing: border-box;
  border-radius: 0.125rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 0.375rem;

  &:hover {
    background-color: $primary-light;
  }

  &:active {
    background-color: $primary-dark;
  }

  &:focus {
    outline: 0.188rem solid rgba($primary-dark, 0.3);
  }

  &.outlined {
    background-color: transparent;
    color: $primary;
    outline: 0.063rem solid $primary;

    &:hover {
      color: $primary-light;
      outline-color: $primary-light;
    }

    &:active {
      color: $primary-dark;
      outline-color: $primary-dark;
    }
  }

  &.secondary {
    background-color: $secondary;
    color: white;

    &.outlined {
      color: $secondary;
      outline-color: $secondary;

      &:hover {
        color: $secondary-light;
        outline-color: $secondary-light;
      }

      &:active {
        color: $secondary-dark;
        outline-color: $secondary-dark;
      }

      &:focus {
        outline-color: rgba($secondary-dark, 0.3);
      }
    }

    &:hover {
      background-color: $secondary-light;
    }

    &:active {
      background-color: $secondary-dark;
    }

    &:focus {
      outline-color: rgba($secondary-dark, 0.3);
    }
  }
  &.transparent,
  &.link {
    background-color: transparent;
    color: $primary;
  }
  &.transparent {
    &:hover {
      background-color: $background-color-2;
    }
    &:active {
      background-color: $background-color-1;
    }
  }
  &.link {
    &:hover,
    &:active {
      text-decoration: underline;
    }
    &:hover {
      color: $primary-light;
      text-decoration-color: $primary-light;
    }
    &:active {
      color: $primary-dark;
      text-decoration-color: $primary-dark;
    }
    &:focus {
      outline: 0.188rem solid rgba($primary-dark, 0.3);
    }
    &.disabled {
      background-color: unset;
    }
  }
  &.disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;

    &.outlined {
      background-color: white;
      color: $semantic-disabled-dark;
      outline-color: $semantic-disabled-dark;
    }
  }

  &.icon-on-right {
    flex-direction: row-reverse;
  }

  &.rounded {
    border-radius: 100rem;
  }

  &.fluid {
    width: 100%;
  }

  &.extra-small {
    padding: 0rem 0.5rem;
    font-size: $smallFont;
    height: 1.5rem;
    &.rounded.icon-only {
      width: 1.5rem;
      padding: unset;
    }
  }

  &.small {
    padding: 0rem 0.875rem;
    font-size: $smallFont;
    height: 2rem;
    &.icon-only {
      width: 2rem;
      padding: unset;
    }
  }

  &.medium {
    padding: 0rem 1rem;
    font-size: $defaultFont;
    height: 2.5rem;
    &.icon-only {
      width: 2.5rem;
      padding: unset;
    }
  }

  &.large {
    padding: 0rem 1.5rem;
    font-size: $largeFont;
    height: 3rem;
    &.icon-only {
      width: 3rem;
      padding: unset;
    }
  }

  &.extra-large {
    padding: 0rem 2rem;
    font-size: $extraLargeFont;
    height: 3.5rem;
    &.icon-only {
      width: 3.5rem;
      padding: unset;
    }
  }
}
