@import './../../common/mixin.scss';

.cms-container {
  z-index: 1302 !important;
}

.menu-section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &.not-available {
    .section-description {
      margin-top: 20px;
    }
  }

  &.error-sidebar {
    .error-title {
      margin-bottom: 10px;
    }
    code {
      font-size: 12px;
    }
  }

  .subcomponent-header {
    padding: 0px 50px;

    .cms-breadcrumb {
      margin-top: 20px;
    }

    margin-bottom: 20px;
  }

  .menu-section-back-button {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    .icon {
      margin-right: 8px;
    }
  }

  .section-title {
    padding: 0px 50px;
    @include text($color: black, $fontSize: 28px, $fontWeight: 400);
  }

  .section-description {
    padding: 0px 50px;
    @include text($color: black, $fontSize: 16px, $fontWeight: 300);
  }

  .menu-items-container {
    > div {
      padding: 40px 50px;
      background-color: white;
      position: relative;

      .main-content {
        background-color: #f8f8f8;
      }
      &:nth-child(2n) {
        background-color: #f8f8f8;

        .main-content {
          background-color: white;
        }
      }

      &:first-child {
        margin-top: 10px;
      }
    }

    .draggable-list-with-number-input {
      .cms-number-increment-decrement-container {
        margin-bottom: 20px;
      }
    }
  }
}

.cms-action-buttons-container {
  display: flex;
  justify-content: flex-end;
  padding: 20px 50px;
  position: sticky;
  z-index: 10000000;
  bottom: 0;
  background-color: white;

  .button-cms {
    font-size: 14px;
    padding: 16px 40px;

    &:not(:first-child) {
      margin-left: 8px;
    }
  }
}

.cms-file-extension-container {
  position: relative;
  display: inline-block;
  .icon {
    background-size: 24px;
    height: 30px;
    width: 24px;
  }

  span.cms-file-extension {
    position: absolute;
    background-color: #1c8576;
    text-transform: uppercase;
    border-radius: 2px;
    padding: 2px;
    bottom: 40%;
    left: -5px;
    transform: translateY(50%);
    @include text($color: #ffffff, $fontSize: 7px, $fontWeight: 500);
  }
}
